import React,{useState} from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor:'#efeeee',
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));



  const rows = [
    createData('9:00 am to 1:00 pm', '9:00 am to 1:00 pm', '9:00 am to 1:00 pm', '9:00 am to 1:00 pm', '9:00 am to 1:00 pm','9:00 am to 1:00 pm','off'),
  ];


function createData(
    mon: string,
    tue: string,
    wed: string,
    thu: string,
    fri: string,
    sat: string,
    sun: string,
) {
    return { mon, tue, wed, thu, fri,sat,sun };
}


function SimpleTable( {timings}) {
  const op_timings = [createData(timings[0],timings[1],timings[2],timings[3],timings[4],timings[5],timings[6])]
  const [rows,setRows] = useState(op_timings);

  return (
    <TableContainer component={Paper}>
    <Table aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell>Monday</StyledTableCell>
          <StyledTableCell align="left">Tuesday</StyledTableCell>
          <StyledTableCell align="left">Wednesday</StyledTableCell>
          <StyledTableCell align="left">Thursday</StyledTableCell>
          <StyledTableCell align="left">Friday</StyledTableCell>
          <StyledTableCell align="left">Saturday</StyledTableCell>
          <StyledTableCell align="left">Sunday</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <StyledTableRow key={row.mon}>
            <StyledTableCell component="th" scope="row">
              {row.mon}
            </StyledTableCell>
            <StyledTableCell align="left">{row.tue}</StyledTableCell>
            <StyledTableCell align="left">{row.wed}</StyledTableCell>
            <StyledTableCell align="left">{row.thu}</StyledTableCell>
            <StyledTableCell align="left">{row.fri}</StyledTableCell>
            <StyledTableCell align="left">{row.sat}</StyledTableCell>
            <StyledTableCell align="left">{row.sun}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  )
}

export default SimpleTable