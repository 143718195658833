import React,{useEffect} from 'react'
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import PhotoFrame from "../components/PhotoFrame"
import Paper from "@mui/material/Paper"
import MedIcon from "../icon-component/MedIcon";
import {useHistory} from 'react-router-dom'
import {departments} from "../../dummy-data/dummy-departments"



export default function Specialities() {
  useEffect(() => {
    document.title = 'Specialitiess';
  },[]);

  const history = useHistory();

  const specialities = departments.filter((dept)=>dept.type != 'services');
  const services = departments.filter((dept)=>dept.type == 'services');

  return (
    <Stack >

      <Box display={"flex"} justifyContent={"flex-start"} flexDirection={"row"}
                sx={{ backgroundRepeat: "no-repeat", 
                minHeight:400,
                backgroundSize: "cover", 
                backgroundImage: `url(https://img.freepik.com/free-photo/female-psychologist-consulting-patient-desk-hospital_1150-15060.jpg?w=1380&t=st=1661091705~exp=1661092305~hmac=4652d880ea8ff036f54d9c7fe0ad27bd90c51f22075d3083e6fcb2543a24e8e7)`}}>
      <Container maxWidth="xl">
        {/* <Typography variant='h3' px={2} textAlign="left">
          {`Our Specialities`}
        </Typography> */}
        </Container>
      </Box>
 
      <Box sx={{ 
         width: "100%", overflowY: "scroll", }} py={4}>
        <Container maxWidth="xl" >

          <Box sx={{paddingY:8}}>
          <Grid container width={"100%"} flexGrow={1} my={2} >
            <Grid item xs={12} display="flex" flexDirection={"row"} justifyContent={"center"}>
              <Typography variant='h2' sx={{paddingY:8}}> Our Specialities</Typography>
            </Grid>
            {
              specialities.map((dept) => (<Grid
                key={dept.id}
                item
                xs={12}
                sm={2}
                md={3}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                onClick={()=>{
                    history.push(`/Specialities/${dept.id}`)
                }}>
                <Box
                  bgcolor={"white"}
                  p={2}
                  m={2}
                  borderRadius={"100%"}
                  sx={{
                    boxShadow: 3,
                    transition: "all .2s",
                    "&:hover": { transform: `scale(1.25)` },
                  }}>
                  <MedIcon
                    name={dept.icon_name}
                    size={64}
                    color="#1976d2"
                  />
                </Box>
                <Typography variant="body1">{dept.name}</Typography>
              </Grid>))
            }
          </Grid>
          </Box>

          <Box sx={{paddingY:8}}>
          <Grid container width={"100%"} flexGrow={1} my={2} >
            <Grid item xs={12} display="flex" flexDirection={"row"} justifyContent={"center"}>
              <Typography variant='h2' sx={{paddingY:8}}> Our Services</Typography>
            </Grid>
            {
              services.map((dept) => (<Grid
                key={dept.id}
                item
                xs={12}
                sm={2}
                md={3}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                onClick={()=>{
                    history.push(`/Specialities/${dept.id}`)
                }}>
                <Box
                  bgcolor={"white"}
                  p={2}
                  m={2}
                  borderRadius={"100%"}
                  sx={{
                    boxShadow: 3,
                    transition: "all .2s",
                    "&:hover": { transform: `scale(1.25)` },
                  }}>
                  <MedIcon
                    name={dept.icon_name}
                    size={64}
                    color="#1976d2"
                  />
                </Box>
                <Typography variant="body1">{dept.name}</Typography>
              </Grid>))
            }
          </Grid>
          </Box>

        </Container>
      </Box>

    </Stack>
  )
}
