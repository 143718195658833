import React,{useEffect} from 'react'
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import PhotoFrame from "../components/PhotoFrame"
import Paper from "@mui/material/Paper"
import Mission from "../icon-component/Mission"
import Vision from "../icon-component/Vision"
import Values from "../icon-component/Diamond"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FolderIcon from '@mui/icons-material/Folder';
import ListItemText from '@mui/material/ListItemText';
import Circle from '@mui/icons-material/Circle';

import aboutBanner from "../../assets/banners/about-services-bg.jpg"

import president from "../../assets/leaders/SWAMI_SATCHIDANANDA.jpg";
import genSecretary from "../../assets/leaders/SWAMI_RHITHAMBHARANANDA.jpg";
import hosSecretary from "../../assets/leaders/SWAMI_VISALANANDA.jpg";
import founder from "../../assets/leaders/DRPNNARAYAN.jpg";


import hospFirstPresident from "../../assets/leaders/BRAHMASRISANKARANADASWAMI.jpg";
import swami from "../../assets/leaders/THEERTHARSWAMIKAL.jpg";



const leaders = [{
    person_name: "DR. P.N NARAYANAN",
    designation: "FOUNDER",
    photo:founder
},
{
    person_name: " SWAMI SATCHIDANANDA",
    designation: "PRESIDENT",
    photo:president
},
{
    person_name: "SWAMI RHITHAMBHARANANDA",
    designation: "GENERAL SECRETARY",
    photo:genSecretary
},
{
    person_name: " SWAMI VISALANANDA",
    designation: "HOSPITAL SECRETARY",
    photo:hosSecretary
},
{
    person_name: "BRAHMASRI SANKARANADA SWAMI",
    designation: "HOSPITAL FIRST PRESIDENT",
    photo: hospFirstPresident
},
{
    person_name: "SREEMATH.SREENARAYANA THEERTHAR SWAMIKAL",
    designation: "",
    photo: swami
}
];




export default function About() {

    useEffect(() => {
        document.title = 'About';
      },[]);



    return (
        <Stack>

            <Box display={"flex"} justifyContent={"flex-start"} flexDirection={"row"} sx={{ backgroundRepeat: "no-repeat", minHeight:800, backgroundSize: "cover", backgroundImage: `url(${aboutBanner})` }}>
            </Box>

            <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Container maxWidth="xl">
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                <Typography variant='h4'>The Background </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant='body1' textAlign={"left"} align="justify" sx={{ lineHeight: "2.5", }} component="p">
                                    Sivagiri Sree Narayana Medical Mission Hospital(SSNMMH) is situated in the holy city of Varkala in Thiruvananthapuram district.
                                    In memory of well-known philosapher Saint Sree Narayana Guru who preached the gospel.”One Cast One Religion  and One God For Man”.
                                    The Hospital owned and controlled Sree Narayanana Dharmga Sanghom Trust Sivagiri which is a public charitable trust founded by the Sree Narayana Guru.
                                    The trust is a body consisting of sanyasins, and registered under regulations 2 of 1088(ME).
                                    The objectives of Sree Narayanan Dharma Sanghom Trust are to do services to humanity and to perpetuate the monastic order of the members,
                                    to hold,administer and develop institution such as Mutts,Temples and other religious and charitable institution like Hospital,School etc.
                                    The Sree Narayana Dharma Sanghom Trust is approved by the Income Tax department.
                                </Typography>
                                <Typography variant='body1' textAlign={"left"} align="justify" sx={{ lineHeight: "2.5", }} component="p">
                                    The nucleus of the SSNMMH was laid in the year 1952 by starting a small unit with minimum conveniences and with meager resources.Since then,
                                    the insitritution has grown up into a major hospital with 350 beds with almost all the specialities available in a modern hospital.
                                    The Hospital has attached with School of Nursing and College of Nursing running ANM,GNM,Bsc Nursing post basic nursing and Msc Nursing courses.
                                </Typography>
                                <Typography variant='body1' textAlign={"left"} align="justify" sx={{ lineHeight: "2.5", }} component="p">
                                    The institution is run on philanthropic basis without any profit motive and it renders services to the general public without any distinction of religion,caste,creed,or color.The administration of the Hospital is vested in managing committiee consisting of 11 members nominated by Dharma Sanghaom Trust.We do a lot of chariotable activities.
                                </Typography>
                                <List>
                                    <ListItem >
                                        <ListItemIcon>
                                            <Circle sx={{fontSize:16}} color='info' />
                                        </ListItemIcon>
                                        <ListItemText primary="Concessions in treatment are given to socially and economically backward patients."/>
                                    </ListItem>
                                    <ListItem >
                                        <ListItemIcon>
                                            <Circle sx={{fontSize:16}} color='info' />
                                        </ListItemIcon>
                                        <ListItemText primary="Conducting free medical camps on a regular basis."/>
                                    </ListItem>
                                    <ListItem >
                                        <ListItemIcon>
                                            <Circle sx={{fontSize:16}} color='info' />
                                        </ListItemIcon>
                                        <ListItemText primary="Conducting free cataract surgeries for poor patient in association with different NGOs."/>
                                    </ListItem>
                                    <ListItem >
                                        <ListItemIcon>
                                            <Circle sx={{fontSize:16}} color='info' />
                                        </ListItemIcon>
                                        <ListItemText primary="Providing free of charge school health care facilities for children."/>
                                    </ListItem>
                                </List>
                                <Typography variant='body1' textAlign={"left"} align="justify" sx={{ lineHeight: "2.5", }} component="p">
                                    Our hospital is a 350 bedded Super Specialty Hospital.Our strength is the team of about 50 highly qualified and well experienced and dedicated senior doctors who are ably assisted by about 250 registered nurses and para medical staff with the focus on delivering most modern health care services.Doctors,Nurses and Paramedical staff are all holding a valid registration from with their respective authority i.e Medical Council of India ,The Kerala Nurses and Midwives Council,Indian Nursing Council etc. The Hospital is approved by the Municipal Authority.
                                    Our hospital is also equipped with 24 hour Trauma Care facility and high tech Operation theatre,Intensive Care Unit and Intensive Coronary Unit.The Emergency Department undertake all kinds of emergencies,including medical,surgical,orthopedics and pediatric.We have also a full pledged Ambulance Service. The Hospital is recognized by Ministry of Health and Family Welfare Government of India.
                                    In this connection we wish to inform you that we have started the new Haemodialysis unit in our hospital in the year 2012.At present we have 8 nos oF B Barun Haemodialysis machine in our  hospital and the unit is functioning with full pledged facilities such as well qualified doctors and technicians.
                                    To add further The Kerala State Government have also accredited  our Hospital(No 213/PH/TVM) under the Karunya Benevolent fund Scheme for the treatment package approved by the sate level committiee under the Head Nephrology maintainnce of Haemodialyisis for chronic kidney disease.
                                    Government of India Department of Space,Vikram Sarabhai Space Center,Trivandrum vide their letter No 6.7/CHSS/2014 dated 04-07-2014 had empanelled Sivagiri Sree Narayana Medical Mission Hospital,Varkala under the contributory Health Service (CHSS) of VSSC/ISRO for the treatmnent of CHSS beneficiaries of VSSC/LPS/IISU/IIST/DAE with effect from 20-07-2014.
                                </Typography>
                            </Box>
                        </Container>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{display: 'flex', flexGrow: 1 }}>

            </Box>

            <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Container maxWidth="xl">
                    <Grid container my={8}>
                        <Grid item xs={12} display="flex" justifyContent={"center"} flexDirection="row">
                            <Typography variant='h2' sx={{ padding: 8 }}>
                                Our Leadership
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <PhotoFrame leader={leaders[1]} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <PhotoFrame leader={leaders[2]} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <PhotoFrame leader={leaders[3]} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Container maxWidth="xl">
                    <Grid container>

                        <Grid item xs={12} display="flex" justifyContent={"center"} flexDirection="row">
                            <Typography variant='h2' sx={{ padding: 8 }}>
                                Mission, Vision and Values
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Paper elevation={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", minHeight: '100%' }}>
                                <Mission width={100} height={100} fill="#1976d2" />
                                <Typography variant='body1' fontSize={20} fontWeight={"bold"} py={2}>Mission</Typography>
                                <Typography variant='body1' p={4} textAlign="justify" sx={{ lineHeight: "2.4" }} fontWeight={"500"}>
                                    Sivagiri Sree Narayana Medical Mission Hospital and its affiliates will be the health care provider of choice for physicians and patients -delivering the health care we expect for our own family-recognized for Exceptional quality,unparalleled service and patient experience,Great place to work and practices academic,Program and research that support care clinical mission,Clinically Integrated affiliates.
                                </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Paper elevation={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", minHeight: '100%' }}>
                                <Vision width={100} height={100} fill="#1976d2" />
                                <Typography variant='body1' fontSize={20} fontWeight={"bold"} py={2}>Vision</Typography>
                                <Typography variant='body1' textAlign="justify" p={4} sx={{ lineHeight: "2.4" }} fontWeight={"500"}>
                                    To improve the health of the people served by providing high quality care, a comprehensive range of service convenient and timely access with exceptional service and compassion.
                                </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Paper elevation={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", minHeight: '100%' }} >
                                <Values width={100} height={100} fill="#1976d2" />
                                <Typography variant='body1' fontSize={20} fontWeight={"bold"} py={2}>Values</Typography>
                                <Typography variant='body1' textAlign="justify" p={4} sx={{ lineHeight: "2.4" }} fontWeight={"500"}>
                                    {`Quality,
            Compassion,  
            Respect,
            Foresight`}
                                </Typography>
                            </Paper>
                        </Grid>

                    </Grid>
                </Container>
            </Box>

        </Stack>
    )
}


/*


        <Stack>
            <Box display={"flex"} justifyContent={"flex-start"} flexDirection={"row"}
                sx={{
                    backgroundRepeat: "no-repeat",
                    minHeight: 800,
                    backgroundSize: "cover",
                    backgroundImage: `url(${aboutBanner})`
                }}>
        </Box>

        <Box sx={{ width: "100%" }} py={2}>

            <Container maxWidth="xl" >

                <Grid container>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent={"flex-start"} flexDirection="column" alignItems={"flex-start"} py={2}>
                            <Typography variant='h2' py={2}>
                                The Background
                            </Typography>
                            <Typography variant='body1' textAlign={"left"} sx={{ lineHeight: "2.4" }}>
                                Sivagiri Sree Narayana Medical Mission Hospital(SSNMMH) is situated in the holy city of Varkala in Thiruvananthapuram district.
                                In memory of well-known philosapher Saint Sree Narayana Guru who preached the gospel.”One Cast One Religion  and One God For Man”.
                                The Hospital owned and controlled Sree Narayanana Dharmga Sanghom Trust Sivagiri which is a public charitable trust founded by the Sree Narayana Guru.
                                The trust is a body consisting of sanyasins, and registered under regulations 2 of 1088(ME).
                                The objectives of Sree Narayanan Dharma Sanghom Trust are to do services to humanity and to perpetuate the monastic order of the members,
                                to hold,administer and develop institution such as Mutts,Temples and other religious and charitable institution like Hospital,School etc.
                                The Sree Narayana Dharma Sanghom Trust is approved by the Income Tax department.
                            </Typography>
                            <Typography variant='body1' textAlign={"left"} sx={{ lineHeight: "2.4" }}>
                                The nucleus of the SSNMMH was laid in the year 1952 by starting a small unit with minimum conveniences and with meager resources.Since then,
                                the insitritution has grown up into a major hospital with 350 beds with almost all the specialities available in a modern hospital.
                                The Hospital has attached with School of Nursing and College of Nursing running ANM,GNM,Bsc Nursing post basic nursing and Msc Nursing courses.
                            </Typography>
                            <Typography variant='body1' textAlign={"left"} sx={{ lineHeight: "2.4" }}>
                                The institution is run on philanthropic basis without any profit motive and it renders services to the general public without any
                                distinction of religion,caste,creed,or color.The administration of the Hospital is vested in managing committiee consisting of 11 members nominated
                                by Dharma Sanghaom Trust.We do a lot of chariotable activities.
                            </Typography>

                            <List>

                                <ListItem >
                                    <ListItemIcon>
                                        <Circle fontSize='small' color='info' />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Concessions in treatment are given to socially and economically backward patients."
                                    />
                                </ListItem>
                                <ListItem >
                                    <ListItemIcon>
                                        <Circle fontSize='small' color='info' />
                                    </ListItemIcon>
                                    <ListItemText

                                        primary="Conducting free medical camps on a regular basis."
                                    />
                                </ListItem>
                                <ListItem >
                                    <ListItemIcon>
                                        <Circle fontSize='small' color='info' />
                                    </ListItemIcon>
                                    <ListItemText

                                        primary="Conducting free cataract surgeries for poor patient in association with different NGOs."
                                    />
                                </ListItem>
                                <ListItem >
                                    <ListItemIcon>
                                        <Circle fontSize='small' color='info' />
                                    </ListItemIcon>
                                    <ListItemText

                                        primary="Providing free of charge school health care facilities for children."
                                    />
                                </ListItem>
                            </List>

                            <Typography variant='body1' textAlign={"left"} sx={{ lineHeight: "2.4" }}>
                                Our hospital is a 350 bedded Super Specialty Hospital.Our strength is the team of about 50 highly qualified and well experienced and dedicated senior doctors who are ably assisted by about 250 registered nurses and para medical staff with the focus on delivering most modern health care services.Doctors,Nurses and Paramedical staff are all holding a valid registration from with their respective authority i.e Medical Council of India ,The Kerala Nurses and Midwives Council,Indian Nursing Council etc. The Hospital is approved by the Municipal Authority.
                                Our hospital is also equipped with 24 hour Trauma Care facility and high tech Operation theatre,Intensive Care Unit and Intensive Coronary Unit.The Emergency Department undertake all kinds of emergencies,including medical,surgical,orthopedics and pediatric.We have also a full pledged Ambulance Service. The Hospital is recognized by Ministry of Health and Family Welfare Government of India.
                                In this connection we wish to inform you that we have started the new Haemodialysis unit in our hospital in the year 2012.At present we have 8 nos oF B Barun Haemodialysis machine in our  hospital and the unit is functioning with full pledged facilities such as well qualified doctors and technicians.
                                To add further The Kerala State Government have also accredited  our Hospital(No 213/PH/TVM) under the Karunya Benevolent fund Scheme for the treatment package approved by the sate level committiee under the Head Nephrology maintainnce of Haemodialyisis for chronic kidney disease.
                                Government of India Department of Space,Vikram Sarabhai Space Center,Trivandrum vide their letter No 6.7/CHSS/2014 dated 04-07-2014 had empanelled Sivagiri Sree Narayana Medical Mission Hospital,Varkala under the contributory Health Service (CHSS) of VSSC/ISRO for the treatmnent of CHSS beneficiaries of VSSC/LPS/IISU/IIST/DAE with effect from 20-07-2014.
                            </Typography>

                        </Box>
                    </Grid>
                </Grid>

                <Grid container my={2} spacing={2} >

                    <Grid item xs={12} my={2}>
                        <Typography variant='h2' textAlign={"left"}>
                            The Founder
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <PhotoFrame leader={leaders[0]} />
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Typography variant='h4' textAlign={"left"}>
                            {`DR. P.N NARAYANAN`}
                        </Typography>
                        <Typography variant='body1' textAlign={"left"} py={1} fontWeight={'bold'}>
                            First secretary and Medical Superintendent
                        </Typography>
                        <Typography variant='body1' textAlign={"left"} sx={{ lineHeight: "2.4" }}>
                            {`In 1952. BRAHMASRI SANKARANADA SWAMI, president of Sivagiri  Mutt at that time ,
                        SREEMATH.SREENARAYANA THEERTHA SWAMIKAL and DR.P.N NARAYAN(Retired medical professional and huge Devotee of Guru)
                        prepared a scheme and submitted the proposal in front of Devaswom Board for financial help and the Board allowed
                        Rs-45000/ as a grant. With that money a 24 bedded hospital and using small donations from public formed a dispensary building.
                        In 1958 a family planning clinic and in 1964 started Auxiliary nursed midwifery school started in association with SSNMM.`}
                        </Typography>


                        <Grid container sx={{}} spacing={2}>
                            <Grid item md={6} xs={12}> <PhotoFrame height={400} leader={leaders[4]} /></Grid>
                            <Grid item md={6} xs={12}> <PhotoFrame height={400} leader={leaders[5]} /></Grid>
                        </Grid>

                    </Grid>

                </Grid>

                <Grid container my={8}>

                    <Grid item xs={12} display="flex" justifyContent={"center"} flexDirection="row">
                        <Typography variant='h2' sx={{ padding: 8 }}>
                            Our Leadership
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <PhotoFrame leader={leaders[1]} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PhotoFrame leader={leaders[2]} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PhotoFrame leader={leaders[3]} />
                    </Grid>


                </Grid>

                <Grid container spacing={2} my={2} >

                    <Grid item xs={12} display="flex" justifyContent={"center"} flexDirection="row">
                        <Typography variant='h2' sx={{ padding: 8 }}>
                            Mission, Vision and Values
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", minHeight: '100%' }}>
                            <Mission width={100} height={100} fill="#1976d2" />
                            <Typography variant='body1' fontSize={20} fontWeight={"bold"} py={2}>Mission</Typography>
                            <Typography variant='body1' p={4} textAlign="justify" sx={{ lineHeight: "2.4" }} fontWeight={"500"}>
                                Sivagiri Sree Narayana Medical Mission Hospital and its affiliates will be the health care provider of choice for physicians and patients -delivering the health care we expect for our own family-recognized for Exceptional quality,unparalleled service and patient experience,Great place to work and practices academic,Program and research that support care clinical mission,Clinically Integrated affiliates.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", minHeight: '100%' }}>
                            <Vision width={100} height={100} fill="#1976d2" />
                            <Typography variant='body1' fontSize={20} fontWeight={"bold"} py={2}>Vision</Typography>
                            <Typography variant='body1' textAlign="justify" p={4} sx={{ lineHeight: "2.4" }} fontWeight={"500"}>
                                To improve the health of the people served by providing high quality care, a comprehensive range of service convenient and timely access with exceptional service and compassion.
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Paper elevation={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", minHeight: '100%' }} >
                            <Values width={100} height={100} fill="#1976d2" />
                            <Typography variant='body1' fontSize={20} fontWeight={"bold"} py={2}>Values</Typography>
                            <Typography variant='body1' textAlign="justify" p={4} sx={{ lineHeight: "2.4" }} fontWeight={"500"}>
                                {`Quality,
                                Compassion,  
                                Respect,
                                Foresight`}
                            </Typography>
                        </Paper>
                    </Grid>

                </Grid>

            </Container>

        </Box>

    </Stack>



*/