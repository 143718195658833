import React from 'react'

import Box from "@mui/material/Box";
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';


const ControlButton = ({next, previous, goToSlide, ...rest}) => {

    const { carouselState: { currentSlide,slidesToShow,totalItems } } = rest;

  
    return(<Box sx={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center",
        padding:4}}
        >
        <WestIcon fontSize="large" 
         sx={{marginX:2,padding:2,backgroundColor:"grey.100", borderRadius:"80%"}}
         className={currentSlide === 0 ? 'disable' : ''}
         onClick={() => previous()}/>
        <EastIcon fontSize="large" sx={{marginX:2,padding:2,backgroundColor:"grey.100", borderRadius:"80%"}}
         onClick={()=>goToSlide(slidesToShow + currentSlide)}/>
 </Box>)

}

export default ControlButton