import React, { useState } from 'react'

import Button from "@mui/material/Button";
import { NavLink } from 'react-router-dom';
import { SpecialitiesList } from './SpecialitiesList';

export const NavMenu = ({ }) => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    }

    return (
        <>
            <Button variant="text" key={1} sx={{ color: "grey.900" }}>
                <NavLink style={(isActive) => ({ textDecoration: 'none', color: isActive ? '#1976d2' : 'inherit' })} to={`/Home`}>{`Home`}</NavLink>
            </Button>
            <Button variant="text" key={2} sx={{ color: "grey.900" }}>
                <NavLink style={(isActive) => ({ textDecoration: 'none', color: isActive ? '#1976d2' : 'inherit' })} to={`/About`}>{`About`}</NavLink>
            </Button>
            <Button variant="text" key={3} sx={{ color: "grey.900" }}>
                <NavLink style={(isActive) => ({ textDecoration: 'none', color: isActive ? '#1976d2' : 'inherit' })} to={`/Doctors`}>{`Doctors`}</NavLink>
            </Button>
            <Button variant="text" key={4} sx={{ color: "grey.900" }}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                disableRipple={true}
                onMouseEnter={handlePopoverOpen}>
                <NavLink style={(isActive) => ({ textDecoration: 'none', color: isActive ? '#1976d2' : 'inherit' })} to={`/Specialities`}>{`Specialities`}</NavLink>
                <SpecialitiesList anchorElement={anchorEl} handleClosePopOver={handlePopoverClose} />
            </Button>
            <Button variant="text" key={5} sx={{ color: "grey.900" }}>
                <NavLink style={(isActive) => ({ textDecoration: 'none', color: isActive ? '#1976d2' : 'inherit' })} to={`/ContactUs`}>{`ContactUs`}</NavLink>
            </Button>
        </>
    )
}



