import React,{useRef,useEffect,useState} from 'react'
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DoctorFrame from '../components/DoctorFrame'
import EastIcon from '@mui/icons-material/East';
import Button from "@mui/material/Button"
import MedIcon from "../icon-component/MedIcon";
import {doctors} from "../../dummy-data/doctors"
import {departments} from "../../dummy-data/dummy-departments"
import {useParams} from "react-router-dom"

/*

const dummy = [
    {
        "id": "1",
        "item": " General Anesthesia for all kinds of Elective & Emergency Surgical Procedures"
    },
    {
        "id": "2",
        "item": " Regional anesthesia for all surgical procedures"
    },
    {
        "id": "3",
        "item": "Ultrasound-guided Regional Anesthesia"
    },
    {
        "id": "4",
        "item": "Anesthesia for the complex spine ( including Scoliosis) & neurological surgery (including Awake Craniotomy)"
    },
    {
        "id": "5",
        "item": "Anesthesia for solid organ transplant( Renal & Hepatic) surgeries"
    },
    {
        "id": "6",
        "item": " Anesthesia for complex Oncosurgeries ( including HIPEC)"
    },
    {
        "id": "7",
        "item": "Anesthesia for Cardiovascular & thoracic Surgery ( including VATS)"
    },
    {
        "id": "8",
        "item": "Anesthesia for patients with difficult airway"
    },
    {
        "id": "9",
        "item": " Anesthesia for Peadiatrics – from newborn to grown-up children"
    },
    {
        "id": "10",
        "item": "Total Intravenous Anesthesia ( for patients with neuromuscular disorders or those in whom GA is contraindicated)"
    },
    {
        "id": "11",
        "item": "24-hour Acute Pain Services ( provides PCA & PCEA)"
    },
    {
        "id": "12",
        "item": "Round the clock consultant Anesthesia Services"
    },
    {
        "id": "13",
        "item": "State of the art Surgical & Trauma ICU"
    }
]


const doctors = [
    {
      id: 1,
      doctorName:"Dr Nasti",
      label: "Doctor",
      gender:"female",
      imgPath:
        "https://previews.123rf.com/images/apoev/apoev1805/apoev180500055/101111721-default-placeholder-doctor-half-length-portrait-photo-avatar-gray-color.jpg",
    },
    {
      id: 2,
      doctorName:"Dr Root",
      label: "Doctor",
      gender:"male",
      imgPath:
        "https://previews.123rf.com/images/apoev/apoev1806/apoev180600169/103284762-default-placeholder-doctor-half-length-portrait-photo-avatar-gray-color.jpg",
    },
    {
      id: 3,
      doctorName:"Dr Watamaniuk",
      label: "Doctor",
      gender:"female",
      imgPath:
        "https://previews.123rf.com/images/apoev/apoev1805/apoev180500055/101111721-default-placeholder-doctor-half-length-portrait-photo-avatar-gray-color.jpg",
    },
    {
      id: 4,
      doctorName:"Dr B. Sick",
      gender:"female",
      label: "Doctor",
      imgPath:
        "https://previews.123rf.com/images/apoev/apoev1805/apoev180500055/101111721-default-placeholder-doctor-half-length-portrait-photo-avatar-gray-color.jpg",
    },
    {
      id: 5,
      doctorName:"Dr Ken Hurt",
      label: "Doctor",
      gender:"male",
      imgPath:
        "https://previews.123rf.com/images/apoev/apoev1806/apoev180600169/103284762-default-placeholder-doctor-half-length-portrait-photo-avatar-gray-color.jpg",
    }
  ];
*/



export default function Speciality() {

    const {id} = useParams();
 
    
    const [doctorsData, setDoctorsData] = useState([]);
    const [deptData, setDeptData] = useState(departments.filter((dept)=>dept.id == id));

    useEffect(()=>{
        document.title = 'Speciality';
        const data = doctors.filter((doctor)=>doctor.DEPT_ID == id);
        setDoctorsData(data);
        setDeptData(departments.filter((dept)=>dept.id == id))
    },[id])
    
    

    const doctorsRef = useRef(null);
    const proceduresRef = useRef();
    const videosRef = useRef();
    const articlesRef = useRef();   


    const handleDoctorScroll = () => doctorsRef.current.scrollIntoView();
    const handleProcedureScroll = () => proceduresRef.current.scrollIntoView();
    const handleVideoScroll = () => videosRef.current.scrollIntoView();
    const handleArticleScroll = () => articlesRef.current.scrollIntoView();

    return (
        <Stack>

            <Box
                py={12}
                sx={{ backgroundRepeat: "no-repeat", 
                backgroundSize: "contain", 
                backgroundImage: `url(${deptData[0].banner})`,
                backgroundColor:'#f9f9f9'
                }}>
                
                <Container maxWidth="xl">
                    <Box display={"flex"} justifyContent={"flex-start"} flexDirection={"row"}  >
                        <MedIcon
                            name={`${deptData[0].icon_name}`}
                            size={64}
                            color="#1976d2"
                        />
                         <Typography variant='h3' px={2}>
                            {deptData[0].name}
                        </Typography>

                    </Box>
                    <Box py={6}>
                        <Typography variant='body1' textAlign={"left"}>
                        {deptData[0].description}
                        </Typography>
                    </Box>
                </Container>

            </Box>

            <Box width={'100%'} py={4}>

                <Container maxWidth="xl">

                    <Grid container spacing={2} >

                        <Grid item xs={12} md={3} >
                            {doctorsData.length > 0  && (<Card sx={{ minWidth: 275, marginY: 2 }} variant="outlined" onClick={handleDoctorScroll}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                                        Our Doctors
                                    </Typography>
                                </CardContent>
                            </Card>)}
                            <Card sx={{ minWidth: 275, marginY: 2 }} variant="outlined" onClick={handleProcedureScroll}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                                        Procedures &#38; Treatments
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ minWidth: 275, marginY: 2 }} variant="outlined" onClick={handleVideoScroll}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                                        Videos
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ minWidth: 275, marginY: 2 }} variant="outlined" onClick={handleArticleScroll}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                                        Articles &#38; Blogs
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={9} >

                        {doctorsData.length > 0  && ( <Box ref={doctorsRef} display={"flex"}  flexDirection={"column"} justifyContent="flex-start" alignItems={"flex-start"} sx={{borderBottomWidth:1, borderColor:"black"}}> 
                                <Typography variant='h4' p={2}>Our Doctors</Typography>
                                <Grid container spacing={2}>
                                    {
                                        doctorsData.length > 0 && doctorsData.map((item)=>(
                                            <Grid item xs={12} md={4} key={item.SL}>
                                                <DoctorFrame item={item} height={500}/>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Box>)}

                            <Box ref={proceduresRef} display={"flex"} flexDirection={"column"} justifyContent="flex-start" alignItems={"flex-start"} py={4}>
                                <Typography variant='h4'> {`Procedures & Treatments`}</Typography>
                                <Typography variant='body1'>Will Update soon</Typography>
                                {/* <List>
                                    {doctorsData.map((item) => (
                                        <ListItem key={item.SL}>
                                            &#8226; <ListItemText primary={item.item} />
                                        </ListItem>
                                    ))}
                                </List> */}
                            </Box>

                            <Box display={"flex"} flexDirection="column" py={4} alignItems="flex-start" sx={{borderBottom:1, borderBottomColor: `rgba(0, 0, 0, 0.12)`}}>
                              <Typography variant='h4'> Videos</Typography>
                              <Button variant='text' endIcon={<EastIcon/>} >View All Videos</Button>
                          </Box>

                          <Box display={"flex"} flexDirection="column" py={4} alignItems="flex-start" sx={{borderBottom:1, borderBottomColor: `rgba(0, 0, 0, 0.12)`}}>
                              <Typography variant='h4'>{`Articles & Blogs`} </Typography>
                              <Button variant='text' endIcon={<EastIcon/>}>View All Articles</Button>
                          </Box>

                        </Grid>

                    </Grid>

                </Container>

            </Box>


        </Stack>
    )
}
