import React, { useEffect } from 'react'
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"


const containerStyle = {
    width: '100%',
    height: '700px'
};

const center = {
    lat: 8.729151,
    lng: 76.733518
};


function  Privacy() {

    useEffect(() => {
        document.title = 'PRIVACY POLICY';
    }, []);

    return (

        <Stack>

            <Box display={"flex"} justifyContent={"flex-start"} flexDirection={"row"}
                sx={{
                    backgroundRepeat: "no-repeat",
                    minHeight: 400,
                    backgroundSize: "cover",
                    backgroundImage: `url(https://img.freepik.com/free-photo/biometric-technology-background-with-fingerprint-scanning-system-virtual-screen-digital-remix_53876-104197.jpg?w=1380&t=st=1675697110~exp=1675697710~hmac=4a72c6615bbf9640ff4952fee17cf13c14b8fcd07997a255da6ff0f4a3b6bfb3)`
                }}>
                <Container maxWidth="xl">
                    {/* <Typography variant='h3' px={2} textAlign="left">
              {`Our Specialities`}
            </Typography> */}
                </Container>
            </Box>

            <Box sx={{ width: "100%" }} py={2}>

                <Container maxWidth="xl" >

                    <Grid container spacing={8}>

                        <Grid item xs={12} display="flex" flexDirection={"column"} alignItems="flex-start">
                            <Typography variant='h5' fontWeight={'bold'}>PRIVACY POLICY</Typography>
                        </Grid>

                        <Grid item xs={12} display="flex" flexDirection={"column"} alignItems="flex-start">
                            
                            <ol>
                            <Typography style={{fontWeight:'bold'}}><li style={{ textAlign: 'left' }}>General</li></Typography>
                                <p style={{ textAlign: 'left' }}>We respect the privacy of every individual who visits our websites or responds to our promotions.
                                    We intend to act reasonably to protect your privacy, but obviously cannot guaranty security against "hackers"
                                    or other issues beyond our control. To better protect your privacy we provide this notice explaining our online
                                    information practices and the choices you can make about the way your information is collected and used.
                                    This notice applies to all information collected or submitted on www.sivagirimissionhospital.com, unless otherwise posted
                                </p>

                                <Typography style={{fontWeight:'bold'}}><li style={{ textAlign: 'left' }}>Information Collected From You</li></Typography>
                                <p style={{ textAlign: 'left' }}>
                                    Personally Identifiable Information refers to information that is collected in conjunction with your name and/or email address. We do not collect any personally identifiable information from you unless you provide it voluntarily.
                                    If you do not want your personally identifiable information collected, do not submit it to us
                                </p>
                                <p style={{ textAlign: 'left' }}>
                                    We may collect other information from you that is not in conjunction with your name.
                                    Although most of this information is collected from you when you submit it voluntarily,
                                    some of it may be collected automatically by use of a “cookie.” Cookies are small text files that reside
                                    on your computer and identify you as a unique user. Cookies allow us to, among other things,
                                    measure activity on our website and to personalize your experience on our website. For instance,
                                    cookies may enable us to remember your viewing preferences without requiring you to re-type a user name and password.
                                    If you choose, you can set your browser to reject cookies or you can manually delete individual or all of the cookies on your computer
                                    by following your browser's help file directions. However, if your browser is set to reject cookies or you manually delete cookies,
                                    you may have some trouble accessing and using some of the pages and features on our website.
                                </p>

                                <Typography style={{fontWeight:'bold'}}><li style={{ textAlign: 'left' }}>How We Use the Information We Collect</li></Typography>
                                <p style={{ textAlign: 'left' }}>We use the information that we collect to complete a contact or request; to provide our products and services to you; to better understand your needs. Please note that we will not contact you unless you have authorized us to contact you.
                                </p>

                                
                                <Typography style={{fontWeight:'bold'}}><li style={{ textAlign: 'left' }}>Sharing Information That You Provide With Others</li></Typography>
                                <p style={{ textAlign: 'left' }}>Generally we do not give or sell your information, including your personally identifiable information, with outside companies except in the following situations:
                                <ul>
                                    <li>Any information you choose to post, including personally identifiable information, may be available generally to the public, or to other members of the blog or online community</li>
                                    <li>Subcontractors. We may share your information with subcontractors that provide us with services. These services may include, but may not be limited to, helping to provide services that you request, helping to create or maintain our databases, helping to research and analyze the people who request services or information from us, helping to provide marketing or solicitation materials for our products or services, and/or helping to process payment card information. In order to provide these services or business operations, your information may be transferred outside the country in which you are based to other locations, which may have different levels of data protection</li>
                                    <li>Law Enforcement. We may report to law enforcement agencies any activities that we reasonably believe to be unlawful, or that we reasonably believe may aid a law enforcement investigation into unlawful activity. In addition, we reserve the right to release your information to law enforcement agencies if we determine, in our sole judgment, that either you have violated our policies, or the release of your information may protect the rights, property or safety of you or another person</li>
                                    <li>Permitted by Law. We may share your information with others as required by, or permitted by, law. This may include sharing your information with governmental entities, or third parties in response to court orders, other legal process, or as we believe is necessary to exercise our legal rights, to defend against legal claims that have been brought against us, or to defend against possible legal claims that we determine in our sole discretion might be brought against us</li>
                                </ul>
                                
                                </p>

                                <Typography style={{fontWeight:'bold'}}><li style={{ textAlign: 'left' }}>Third-party websites</li></Typography>
                                <p style={{ textAlign: 'left' }}>Any links to third-party websites on www.sivagirimissionhospital.com sites are provided solely as a convenience to you. If you use these links, you will leave the www.sivagirimissionhospital.com site. We do not review all third-party sites, nor do it control or assume responsibility for the content or privacy policies of any of these sites</p>
                                

                                <Typography style={{fontWeight:'bold'}}><li style={{ textAlign: 'left' }}>How can you correct your information</li></Typography>
                                <p style={{ textAlign: 'left' }}>If you have any questions regarding this privacy policy or would like to have your personally identifiable information modified or deleted from our records, please e-mail us.</p>
                               

                                <Typography style={{fontWeight:'bold'}}><li style={{ textAlign: 'left' }}>How to contact us, and how we may contact you</li></Typography>
                                <p style={{ textAlign: 'left' }}>Should you have other questions or concerns about this privacy policy, contact us- visit our contact us page</p>
                               

                                <Typography style={{fontWeight:'bold'}}><li style={{ textAlign: 'left' }}>Changes to this Policy</li></Typography>
                                <p style={{ textAlign: 'left' }}>This Policy is current as of the Effective Date set forth below.  We may change this Policy from time to time, so please be sure to check back periodically. We will post any changes to this Policy on our website, at www.sivagirimissionhospital.com /privacy. If we make any changes to this Policy that materially affect our practices with regard to the personal information we have previously collected from you, we will endeavor to provide you with notice in advance of such change by highlighting the change on our website, or by emailing registered users at the most recent email address they provided</p>
                               
                            </ol>

                            
                        </Grid>



                    </Grid>

                </Container>



            </Box>

        </Stack>
    )
}

export default Privacy;