import * as React from "react";
import { SVGProps } from "react";

const SvgDiamond = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="diamond_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 144 168"
    {...props}
  >
    <defs>
      <style>{".diamond_svg__cls-1{}"}</style>
    </defs>
    <path
      className="diamond_svg__cls-1"
      d="m44.13 97.86 17.52 65.69L3.72 97.86ZM44.46 88.47H3.72L30.5 61.69a4.69 4.69 0 0 1 3.32-1.38h26.73ZM87.45 88.47H55.27l16.09-28.16ZM88.88 97.86l-17.52 65.69-17.52-65.69ZM98.26 88.47 82.17 60.31h26.73a4.71 4.71 0 0 1 3.32 1.38L139 88.47ZM98.59 97.86H139l-57.93 65.69ZM71.36 30.24a4.7 4.7 0 0 1-4.69-4.7V8.17a4.69 4.69 0 0 1 9.38 0v17.37a4.7 4.7 0 0 1-4.69 4.7ZM24.71 39.92 16 24.88a4.69 4.69 0 1 1 8.12-4.69l8.69 15a4.69 4.69 0 1 1-8.13 4.69ZM111.59 41.64a4.69 4.69 0 0 1-1.71-6.41l8.68-15a4.69 4.69 0 0 1 8.13 4.69l-8.69 15a4.69 4.69 0 0 1-6.41 1.72Z"
    />
  </svg>
);

export default SvgDiamond;
