import React from 'react'
import { Box, Button, Container, Typography, Grid } from "@mui/material";
import { NavLink } from 'react-router-dom'
import MedIcon from "../icon-component/MedIcon";

import { departments } from "../../dummy-data/dummy-departments";


export const ShortSpeciality = () => {

    const specialities = departments.filter((dept) => {
        if (dept.type != 'services' && (dept.id == 2 || dept.id == 13 || dept.id == 5 ||
            dept.id == 4 || dept.id == 17 || dept.id == 10)) {
            return dept;
        }
    });

    return (
        <Container maxWidth="xl" sx={{ paddingY: '15px' }}>
            <Grid container>
                <Grid item xs={12} md={5} sm={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h4" align="left"> Our Specialities</Typography>
                        <Box sx={{ marginY: '20px', display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="body1" align="left">Our aim is to deliver state-of-the-art medical care. We are committed to providing the best patient care and welcome the opportunity to serve you and your family</Typography>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "primary",
                                    color: "white",
                                    borderRadius: 50,
                                    paddingY: 1,
                                    textTransform: 'none',
                                    fontSize: 16,
                                    alignSelf: 'flex-start',
                                    marginY: 1
                                }}
                                size="medium">
                                <NavLink to={`/Specialities`} style={() => ({ textDecoration: "none", color: "white" })}>View All</NavLink>
                            </Button>
                        </Box>

                    </Box>
                </Grid>
                <Grid item xs={12} md={7} sm={12}>
                    <Grid container>
                        {specialities.map((dept)=>(<Grid item key={dept.id} xs={12} sm={4} md={4}display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                            <Box
                                bgcolor={"white"}
                                p={2}
                                m={2}
                                borderRadius={"100%"}
                                sx={{
                                    boxShadow: 3,
                                    transition: "all .2s",
                                    "&:hover": { transform: `scale(1.25)` },
                                }}>
                                <MedIcon
                                    name={`${dept.icon_name}`}
                                    size={64}
                                    color="#1976d2"
                                />
                            </Box>
                            <Typography variant="body1">{`${dept.name}`}</Typography>
                        </Grid>))}
                    </Grid>
                </Grid>
            </Grid>

        </Container>
    )
}
