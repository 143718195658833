import React from 'react'


import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import MedIcon from "../icon-component/MedIcon";
import {useHistory } from 'react-router-dom';


import { departments } from "../../dummy-data/dummy-departments";


// pop-over menu
export const SpecialitiesList = ({anchorElement,handleClosePopOver}) => {

    const specialities = departments.filter((dept) => dept.type != 'services');
    const history = useHistory();

  return (
    <Menu
    id="simple-menu"
    anchorEl={anchorElement}
    open={Boolean(anchorElement)}
    onClose={handleClosePopOver}
    MenuListProps={{ onMouseLeave: handleClosePopOver }}>
    <Box sx={{ width: 1200 }}>
        <Grid container>
            {specialities.map((dept) => (<Grid key={dept.id} item sm={3} onClick={() => {
                handleClosePopOver();
                history.push(`/Specialities/${dept.id}`)
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', px: 1, '&:hover': { color: "#c71d22", cursor: 'pointer' } }}>
                    <MedIcon
                        name={`${dept.icon_name}`}
                        size={20}
                        color="#1976d2"
                    />
                    <Typography sx={{ py: 1, mx: 1, borderBottom: 1, borderBottomColor: 'grey.200' }}>{dept.name}</Typography>
                </Box>
            </Grid>))}
        </Grid>
    </Box>
</Menu>
  )
}
