import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Link} from 'react-router-dom'
import ControlButton from "./ControlButton"
import {doctors} from "../../dummy-data/doctors"
import DoctorFrame from '../components/DoctorFrame';




const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4 
  },
  tablet: {
    breakpoint: { max: 1024, min: 500 },
    items: 2,
    slidesToSlide: 2 
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 1,
    slidesToSlide: 1 
  },
};


const DoctorStepper = () => {
  return (
    <Container maxWidth="xl">

        <Box sx={{
            display:"flex",
            flexDirection:"row",
            justifyContent:"center",
            alignItems:"center",
            paddingY:2
        }}>
            <Typography variant="h3">Our Doctors</Typography>
        </Box>

      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={false} // means to render carousel on server-side.
        infinite={true}
        rewind
        autoPlay={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all 0.5s ease-out"
        additionalTransfrom={0}
        transitionDuration={3000}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        itemClass="carousel-item-padding-40-px"
        arrows={false}
        minimumTouchDrag={80}
        renderButtonGroupOutside={true}
        customButtonGroup={<ControlButton next previous goToSlide carouselState/>}>
        {doctors.length > 0 && doctors.map((step, index) => (<DoctorFrame item={step} height={700} key={step.SL}/>))}
      </Carousel>


     <Box
      sx={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center",
        padding:0}}>
        <Button  variant="contained" color="primary" sx={ { fontSize:16, borderRadius: 28,paddingX:4,paddingY:1}}>
          <Link style={{textDecoration:'none', color:"white"}} to={`/Doctors`}>View All</Link>
        </Button>
     </Box>
    
        
    </Container>
  );
};

export default DoctorStepper;
