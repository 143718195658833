import React from 'react'
import { Box, Button, Container, Typography} from "@mui/material";
import {NavLink} from 'react-router-dom'


//short about section for home
export const ShortAbout = () => {
    return (
        <Container maxWidth="xl" sx={{ paddingY: '15px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h4" align="left">
                    {`About - Sivagiri Sree Narayana Medical Mission Hospital, Varkala `}
                </Typography>
                <Box sx={{ width: '75%', marginY: '20px', display:'flex', flexDirection:'column' }}>
                    <Typography variant="body1" align="left">
                        {`Sivagiri Sree Narayana Medical Mission Hospital(SSNMMH) is situated in the holy city of Varkala in Thiruvananthapuram district of Kerala. In memory of well-known philosapher Saint Sree Narayana Guru who preached the gospel.”One Cast One Religion  and One God For Man” .....`}
                    </Typography>
                    <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "primary",
                        color: "white",
                        borderRadius: 50,
                        paddingY: 1,
                        textTransform: 'none',
                        fontSize: 16,
                        alignSelf:'flex-start',
                        marginY:1
                    }}
                    size="medium">
                    <NavLink to={`/About`} style={() => ({ textDecoration: "none", color: "white" })}>Read Our Story</NavLink>
                </Button>
                </Box>
            </Box>
        </Container>
    )
}
