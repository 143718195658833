import React from 'react'
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Divider from '@mui/material/Divider';
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import {Link,useHistory} from 'react-router-dom'
import { nameAliaser} from '../utils/Helper'

 


const DoctorFrame = ({ item, height }) => {

  const history = useHistory();

  const viewProfileHandler = (item) => {
        history.push(`/Doctors/${nameAliaser(item.NAME)}`,{...item});
  }

  return (
      <Stack minHeight={height} sx={{borderRadius:4,border:1, borderColor:"#1976d2",marginX: 0.5,overflow:'hidden'}}>
          <Box sx={{}}>
            <Box sx={{height:'100%',width:'100%'}} component="img" src={item?.PHOTO_PATH} alt={item?.NAME}/>
          </Box>
          <Box sx={{}}>
            <Box>
              <Typography variant="h6" px={2}>{`${item.NAME}`}</Typography>
              <Typography variant="body2" fontSize={14} px={2}>{item.QUALIFICATIONS}</Typography>
            </Box>
          </Box>
          <Box sx={{flexGrow:1,display:'flex', flexDirection:'column',justifyContent:'flex-end'}}>
            <Box sx={{display:'flex', flexDirection:'column',justifyContent:'center',paddingX:2}}>
             <Typography  variant="body1" sx={{borderBottom: 2,borderTop: 2,borderColor: "grey.200",paddingY:1}}>{item.DEPARTMENT}</Typography>
            </Box>
            <Box sx={{ display:'flex', flexDirection:'column',justifyContent:'space-between',padding:1}}>
                <Typography variant="body1" >SSNMMH VARKALA</Typography>
                <Button  variant="contained" color="primary" sx={ {fontSize:16, borderRadius: 28,paddingX:4,marginY:1}} onClick={()=>viewProfileHandler(item)}>View Details</Button>
                <Button disabled variant="outlined" color="primary">Book An Appointment</Button>
            </Box>
          </Box>
      </Stack>
    )
}

export default DoctorFrame;
