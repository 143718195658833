import * as React from "react";
import { SVGProps } from "react";

const SvgMission = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 144 168"
    {...props}
  >
    <path
      d="m139.11 142.78-31.34-57a3 3 0 0 0-2.57-1.48 2.84 2.84 0 0 0-2.49 1.61l-10.86 22.47-32.18-59V38.72H91a2.85 2.85 0 0 0 2-4.87l-9.38-9.38L93 15.09a2.85 2.85 0 0 0-2-4.86H56.82A2.84 2.84 0 0 0 54 13.08v36.31L3 142.78A2.85 2.85 0 0 0 5.53 147h131.08a2.85 2.85 0 0 0 2.5-4.22ZM56.82 56.07 71.4 82.8l-5.72 5.72-6.58-8.77a3 3 0 0 0-4.55 0L48 88.52l-5.76-5.72Z"
      style={{
      }}
    />
  </svg>
);

export default SvgMission;
