import * as React from "react";
import { SVGProps } from "react";

const SvgVision = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 144 168"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="m139 92.82-22.17-59A20.06 20.06 0 0 0 78 40.88v25.78H66.5V40.88a20.06 20.06 0 0 0-38.84-7.06L5.48 92.8a31.52 31.52 0 1 0 61 11.1v-2.86H78v2.86a31.52 31.52 0 1 0 61-11.09ZM35 124a20.06 20.06 0 1 1 20-20.09A20.08 20.08 0 0 1 35 124Zm74.5 0a20.07 20.07 0 1 1 20.06-20.06A20.08 20.08 0 0 1 109.48 124Z"
      style={{
      }}
    />
  </svg>
);

export default SvgVision;
