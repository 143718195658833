import React from "react";
import Icon from "./Icomoon";

export default function MedIcon(props) {
  const { name, size, color } = props;
  return (<Icon icon={name} size={size} color={color}/>);
}

/*
[
    "chiropractic-physiotherapy-svgrepo-com",
    "anatomy-kidneys-nephron-svgrepo-com",
    "bladder-kidneys-nephron-svgrepo-com1",
    "baby-fetus-obstetrics-svgrepo-com",
    "ayurvedic-bowl-svgrepo-com",
    "baby-boy-svgrepo-com",
    "baby-svgrepo-com",
    "bladder-kidneys-nephron-svgrepo-com",
    "brain-svgrepo-com",
    "eye-svgrepo-com",
    "heal-svgrepo-com",
    "healthcare-lungs-svgrepo-com",
    "healthcare-stomach-svgrepo-com",
    "heart-care-svgrepo-com",
    "i-dental-svgrepo-com",
    "i-ear-nose-throat-svgrepo-com",
    "i-mri-pet-svgrepo-com",
    "intensive-care-svgrepo-com",
    "i-pathology-svgrepo-com",
    "i-womens-health-svgrepo-com",
    "kidney-svgrepo-com",
    "knee-svgrepo-com",
    "lungs-svgrepo-com",
    "medical-svgrepo-com",
    "medical-truck-svgrepo-com",
    "medicine-svgrepo-com",
    "neurology-svgrepo-com",
    "noun-fetus-576007",
    "noun-homeopathy-3059496",
    "nurse-head-svgrepo-com",
    "patient-with-injured-bandaged-head-standing-with-saline-via-intravenous-line-svgrepo-com",
    "psychologist-svgrepo-com",
    "radiologist-physician-x-ray-radiology-svgrepo-com",
    "respiratory-svgrepo-com",
    "surgery-svgrepo-com",
    "surgery-svgrepo-com-1",
    "wrapped-baby-svgrepo-com"
]*/
