import React,{useEffect,useState} from 'react'
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import DoctorFrame from '../components/DoctorFrame'
import {doctors} from "../../dummy-data/doctors"



function Doctors() {

const [doctorsList,setDoctorsList] = useState([]);
const data = doctors;

  useEffect(() => {
    console.log("doctors length ",doctors.length)
    document.title = 'Dcotors';
    setDoctorsList(data);
  },[data]);
  return (
    <Stack>

<Box display={"flex"} justifyContent={"flex-start"} flexDirection={"row"}
                sx={{ backgroundRepeat: "no-repeat", 
                minHeight:400,
                backgroundSize: "cover", 
                backgroundImage: `url(https://images.pexels.com/photos/4021769/pexels-photo-4021769.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)`}}>
      <Container maxWidth="xl">
        {/* <Typography variant='h3' px={2} textAlign="left">
          {`Our Specialities`}
        </Typography> */}
        </Container>
      </Box>

    <Box sx={{ width: "100%" }} py={2}>

      <Container maxWidth="xl" >

        <Grid container width={"100%"} flexGrow={1} my={2} spacing={2}>

          <Grid item xs={12} display="flex" flexDirection={"row"} justifyContent={"center"}>
            <Typography variant='h2' p={2}> Our Doctors</Typography>
          </Grid>

          {
            doctorsList.length > 0 &&  (doctorsList.map((doctor) => (<Grid item key={doctor.SL} xs={12} sm={6} md={3}><DoctorFrame item={doctor} height={700}/></Grid>)))
          }

        </Grid>

      </Container>

    </Box>

  </Stack>
  )
}

export default Doctors