import drrahulls from './../assets/doctors/drrahulls.jpg'
import dranoopr from './../assets/doctors/dranoopr.jpg'
import drbinsabasheer from './../assets/doctors/drbinsabasheer.jpg'
import drfirozahakkim from './../assets/doctors/drfirozahakkim.jpg'
import drginiak from './../assets/doctors/drginiak.jpg'
import drmadhusasidharan from './../assets/doctors/drmadhusasidharan.jpg'

import drnishadsk from './../assets/doctors/drnishadsk.jpg'
import drsajid from './../assets/doctors/drsajid.jpg'
import drshibinas from './../assets/doctors/drshibinas.jpg'
import drsneham from './../assets/doctors/drsneham.jpg'
import drsreedas from './../assets/doctors/drsreedas.jpg'

import drsyamlal from './../assets/doctors/drsyamlal.jpg'
import drvinayaksisruthan from './../assets/doctors/drvinayaksisruthan.jpg'



import drmeenakumari from './../assets/doctors/drmeenakumari.jpg'

import dranuvbabu from './../assets/doctors/dranuvbabu.jpg'
import drjoshi from './../assets/doctors/drjoshi.jpg'
import drnavadeep from './../assets/doctors/drnavadeep.jpg'
import drniranjan from './../assets/doctors/drniranjan.jpg'
import drrajaykumar from './../assets/doctors/drrajaykumar.jpg'
import drshammy from './../assets/doctors/drshammy.jpg'
import drtitty from './../assets/doctors/drtitty.jpg'


import drakshaanil from './../assets/doctors/drakshaanil.jpg'
import drsurabhisuseelan from './../assets/doctors/drsurabhisuseelan.jpg'
import drbinujababu from './../assets/doctors/drbinujababu.jpg'   


import drdummyfemale from './../assets/doctors/drdummyfemale.jpg'
import drdummymale from './../assets/doctors/drdummymale.jpg' 




export const doctors = [
    {
        "SL": 1,
        "NAME": "Dr. ARAVIND T I",
        "GENDER": "MALE",
        "DEPARTMENT": "ENT",
        "DEPT_ID": 17,
        "DESIGNATION": "ENT",
        "QUALIFICATIONS": "MBBS, MS (ENT)",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["10.30 AM TO 1.00 PM", "NO-OP", "NO-OP", "NO-OP", "10.30 AM TO 1.00 PM", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummymale,
    },
    {
        "SL": 2,
        "NAME": "Dr. ( Col ) K E RAJAN",
        "GENDER": "MALE",
        "DEPARTMENT": "PULMONOLOGY",
        "DEPT_ID": 20,
        "DESIGNATION": "PULMONOLOGIST",
        "QUALIFICATIONS": "MD (MEDICINE) , MD ( RESPIRATORY MEDICINE)",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["10.30 AM TO 1.00 PM", "NO-OP", "NO-OP", "NO-OP", "10.30 AM TO 1.00 PM", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummymale
    },
    {
        "SL": 3,
        "NAME": "Dr. ABHILASH RAMAN",
        "GENDER": "MALE",
        "DEPARTMENT": "ORTHOPAEDICS",
        "DEPT_ID": 4,
        "DESIGNATION": "ORTHOPAEDICS",
        "QUALIFICATIONS": "MBBS, D-ORTHO CONSULTANT ORTHOPEDICIAN",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["8.30 AM TO 1.30 PM, 4.00 PM TO 6 PM", "8.30 AM TO 1.30 PM, 4.00 PM TO 6 PM", "8.30 AM TO 1.30 PM, 4.00 PM TO 6 PM", "8.30 AM TO 1.30 PM, 4.00 PM TO 6 PM", "8.30 AM TO 1.30 PM, 4.00 PM TO 6 PM", "8.30 AM TO 1.30 PM, 4.00 PM TO 6 PM", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummymale
    },
    {
        "SL": 4,
        "NAME": "Dr. AKSHA ANIL",
        "GENDER": "FEMALE",
        "DEPARTMENT": "HOMEOPATHY",
        "DEPT_ID": 26,
        "DESIGNATION": "HOMEOPATHY",
        "QUALIFICATIONS": "BHMS ( HOMEOPATHIC MEDICINE AND SURGERY )",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["8 AM TO 3 PM", "8 AM TO 3 PM", "8 AM TO 3 PM", "8 AM TO 3 PM", "8 AM TO 3 PM", "8 AM TO 3 PM", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drakshaanil
    },
    {
        "SL": 5,
        "NAME": "Dr. ANIL PRASAD K",
        "GENDER": "MALE",
        "DEPARTMENT": "PSYCHAIATRY",
        "DEPT_ID": 8,
        "DESIGNATION": "PSYCHAIATRIST",
        "QUALIFICATIONS": "MBBS, DA, DPM",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["9 AM TO 1 PM", "NO-OP", "9 AM TO 1 PM", "NO-OP", "9 AM TO 1 PM", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummymale
    },
    {
        "SL": 6,
        "NAME": "Dr. ANOOP R",
        "GENDER": "MALE",
        "DEPARTMENT": "GASTROENTEROLOGY",
        "DEPT_ID": 12,
        "DESIGNATION": "GASTROENTEROLOGIST",
        "QUALIFICATIONS": "",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "5.30 pm to 7 pm", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": dranoopr
    },
    {
        "SL": 7,
        "NAME": "Dr. ANU V BABU",
        "GENDER": "MALE",
        "DEPARTMENT": "SURGERY",
        "DEPT_ID": 1,
        "DESIGNATION": "SURGEON",
        "QUALIFICATIONS": "MBBS, MS,GENERAL AND LAPAROSCOPIC SURGEON",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": dranuvbabu
    },
    {
        "SL": 8,
        "NAME": "Dr. BHAVANI L NAIR",
        "GENDER": "FEMALE",
        "DEPARTMENT": "GYNAECOLOGIST",
        "DEPT_ID": 5,
        "DESIGNATION": "OBSTETRICS AND GYNAECOLOGY",
        "QUALIFICATIONS": "",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummyfemale
    },
    {
        "SL": 9,
        "NAME": "Dr. BINSA BASHEER",
        "GENDER": "FEMALE",
        "DEPARTMENT": "ENT",
        "DEPT_ID": 17,
        "DESIGNATION": "ENT",
        "QUALIFICATIONS": "MBBS, MS, ENT, DNB (ENT)",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drbinsabasheer
    },
    {
        "SL": 10,
        "NAME": "Dr. BINUJA BABU",
        "GENDER": "FEMALE",
        "DEPARTMENT": "Gynaecology",
        "DESIGNATION": "GYNAECOLOGIST",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drbinujababu
    },
    {
        "SL": 11,
        "NAME": "Dr. FIROZ A HAKKIM",
        "GENDER": "MALE",
        "DEPARTMENT": "PULMONOLOGY",
        "DESIGNATION": "PULMONOLOGIST",
        "QUALIFICATIONS": "MBBS, MD, ( PULMONARY MEDICINE )",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drfirozahakkim
    },
    {
        "SL": 12,
        "NAME": "Dr. GINI ",
        "GENDER": "MALE",
        "DEPARTMENT": "UROLOGY",
        "DEPT_ID": 16,
        "DESIGNATION": "UROLOGIST",
        "QUALIFICATIONS": "MBBS, MS, MCH, (URO)",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drginiak
    },
    {
        "SL": 13,
        "NAME": "Dr. GIRISH HIMA VINDHYA V J",
        "GENDER": "MALE",
        "DEPARTMENT": "Dental,Clinical,Oral & Maxillofacial Surgery",
        "DEPT_ID": 11,
        "DESIGNATION": "DENTIST",
        "QUALIFICATIONS": "BDS",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummymale
    },
    {
        "SL": 14,
        "NAME": "Dr. JISHA R RAJU",
        "GENDER": "FEMALE",
        "DEPARTMENT": "OBSTETRICS AND GYNAECOLOGY",
        "DEPT_ID": 5,
        "DESIGNATION": "GYNAECOLOGIST",
        "QUALIFICATIONS": "MBBS, MS, DGO, FRM",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["3.30PM TO 6.30 PM", "3.30PM TO 6.30 PM", "3.30PM TO 6.30 PM", "3.30PM TO 6.30 PM", "3.30PM TO 6.30 PM", "3.30PM TO 6.30 PM", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummyfemale
    },
    {
        "SL": 15,
        "NAME": "Dr. JOSHI K",
        "GENDER": "MALE",
        "DEPARTMENT": "EMERGENCY DEPARTMENT",
        "DEPT_ID": 7,
        "DESIGNATION": "CMO",
        "QUALIFICATIONS": "MBBS",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drjoshi
    },
    {
        "SL": 16,
        "NAME": "Dr. K R JAYAKUMAR",
        "GENDER": "MALE",
        "DEPARTMENT": "YOGA & NATUROPATHY",
        "DEPT_ID": 3,
        "DESIGNATION": "YOGA & NATUROPATHY",
        "QUALIFICATIONS": "ND ( OSM) , MA (SOC) , MSc (PSY), P.G. Dip ( Guidance & councelling)",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["9 AM TO 1 PM , 4 PM TO 6 PM", "9 AM TO 1 PM , 4 PM TO 6 PM", "9 AM TO 1 PM , 4 PM TO 6 PM", "9 AM TO 1 PM , 4 PM TO 6 PM", "9 AM TO 1 PM , 4 PM TO 6 PM", "9 AM TO 1 PM , 4 PM TO 6 PM", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummymale
    },
    {
        "SL": 17,
        "NAME": "Dr. KITTY ELIZABETH MAMMEN",
        "GENDER": "MALE",
        "DEPARTMENT": "Gynaecology",
        "DEPT_ID": 5,
        "DESIGNATION": "obstetrics and gynaecology",
        "QUALIFICATIONS": "MBBS, MS,(OBS AND GYNAEC) DIP IN LAPROSCOPY)",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["4PM TO 6PM", "4PM TO 6PM", "4PM TO 6PM", "4PM TO 6PM", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummyfemale
    },
    {
        "SL": 18,
        "NAME": "Dr. KRISHNADAS",
        "GENDER": "MALE",
        "DEPARTMENT": "Gynaecology",
        "DEPT_ID": 5,
        "DESIGNATION": "obstetrics and gynaecology",
        "QUALIFICATIONS": "MBBS, MD, FMAS, FSM, F.A.R",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "9 AM TO 12 PM", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummymale
    },
    {
        "SL": 19,
        "NAME": "Dr. MADHU SASIDHARAN",
        "GENDER": "MALE",
        "DEPARTMENT": "GASTROENTEROLOGY",
        "DEPT_ID": 12,
        "DESIGNATION": "GASTROENTEROLOGIST",
        "QUALIFICATIONS": "MBBS, MD, DM, GASTRO",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "4.30 PM O 6 PM", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drmadhusasidharan
    },
    {
        "SL": 20,
        "NAME": "Dr. MEENAKUMARI",
        "GENDER": "FEMALE",
        "DEPARTMENT": "Gynaecology",
        "DEPT_ID": 5,
        "DESIGNATION": "obstetrics and gynaecology",
        "QUALIFICATIONS": "MBBS, DGO",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["9.30 PM TO 1PM,3 PM TO 6 PM", "9.30 PM TO 1PM,3 PM TO 6 PM", "9.30 PM TO 1PM,3 PM TO 6 PM", "9.30 PM TO 1PM,3 PM TO 6 PM", "9.30 PM TO 1PM,3 PM TO 6 PM", "9.30 PM TO 1PM,3 PM TO 6 PM", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drmeenakumari
    },
    {
        "SL": 21,
        "NAME": "Dr. NAVADEEP RAVIKUMAR",
        "GENDER": "MALE",
        "DEPARTMENT": "DERMATOLOGY",
        "DEPT_ID": 6,
        "DESIGNATION": "DERMATOLOGIST",
        "QUALIFICATIONS": "MBBS, DDVL",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["10 AM TO 1 PM", "NO-OP", "10 AM TO 1 PM", "NO-OP", "10 AM TO 1 PM", "10 AM TO 1 PM", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drnavadeep
    },
    {
        "SL": 22,
        "NAME": "Dr. NIRANJAN T J",
        "GENDER": "MALE",
        "DEPARTMENT": "ORHOPAEDICS",
        "DEPT_ID": 4,
        "DESIGNATION": "ORTHOPAEDICS",
        "QUALIFICATIONS": "MBBS,D-ORTHO, DNB (ORHO, MNAMS, Fellowship in Arthroplasty)",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["9 AM TO 1 PM", "NO-OP", "9 AM TO 1 PM", "NO-OP", "9 AM TO 1 PM", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drniranjan
    },
    {
        "SL": 23,
        "NAME": "Dr. R AJAY KUMAR",
        "GENDER": "MALE",
        "DEPARTMENT": "CARDIOLOGY",
        "DEPT_ID": 13,
        "DESIGNATION": "CARDIOLOGIST",
        "QUALIFICATIONS": "MBBS, MD, DM, FIC, FNCC, FESE.",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["10.30 AM TO 12 PM", "10.30 AM TO 12 PM", "NO-OP", "NO-OP", "10.30 AM TO 12 PM", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drrajaykumar
    },
    {
        "SL": 24,
        "NAME": "Dr. RAHUL L S",
        "GENDER": "MALE",
        "DEPARTMENT": "ORHOPAEDICS",
        "DEPT_ID": 4,
        "DESIGNATION": "ORHOPAEDICS",
        "QUALIFICATIONS": "MBBS, DNB",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": [" 8 AM TO 1 PM & 4 PM TO 6 PM", " 8 AM TO 1 PM & 4 PM TO 6 PM", " 8 AM TO 1 PM & 4 PM TO 6 PM", " 8 AM TO 1 PM & 4 PM TO 6 PM", " 8 AM TO 1 PM & 4 PM TO 6 PM", " 8 AM TO 1 PM & 4 PM TO 6 PM", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drrahulls
    },
    {
        "SL": 25,
        "NAME": "Dr. RAJIV MOHAN",
        "GENDER": "MALE",
        "DEPARTMENT": "ANAESTHESIOLOGY",
        "DEPT_ID": 27,
        "DESIGNATION": "ANAESTHETIST",
        "QUALIFICATIONS": "MBBS, DA, DNB",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummymale
    },
    {
        "SL": 26,
        "NAME": "Dr. S K NISHAD",
        "GENDER": "MALE",
        "DEPARTMENT": "GENERAL MEDICINE",
        "DEPT_ID": 2,
        "DESIGNATION": "PHYSICIAN",
        "QUALIFICATIONS": "MBBS, MD, CONSULTANT IN INTERNAL MEDICINE AND DIABETOLOGY",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["9 AM TO 1 PM", "9 AM TO 1 PM", "9 AM TO 1 PM", "9 AM TO 1 PM", "9 AM TO 1 PM", "9 AM TO 1 PM", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drnishadsk
    },
    {
        "SL": 27,
        "NAME": "Dr. S SHAJI",
        "GENDER": "MALE",
        "DEPARTMENT": "PAEDIATRICS",
        "DEPT_ID": 10,
        "DESIGNATION": "PAEDIATRICIAN",
        "QUALIFICATIONS": "MBBS, MD, ( PAED)",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummymale
    },
    {
        "SL": 28,
        "NAME": "Dr. SAJID B",
        "GENDER": "MALE",
        "DEPT_ID": 27,
        "DEPARTMENT": "ANAESTHESIOLOGY",
        "DESIGNATION": "ANAESTHETIST",
        "QUALIFICATIONS": "DNB (ANAES)",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drsajid
    },
    {
        "SL": 29,
        "NAME": "Dr. SANTHOSH S",
        "GENDER": "MALE",
        "DEPARTMENT": "PSYCHOLOGIST",
        "DEPT_ID": 8,
        "DESIGNATION": "PSYCHOLOGIST",
        "QUALIFICATIONS": "MSc, (psc), MA(socio), PGD-CAFC",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["9.30 AM TO 3.30 PM", "9.30 AM TO 3.30 PM", "9.30 AM TO 3.30 PM", "9.30 AM TO 3.30 PM", "9.30 AM TO 3.30 PM", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummymale
    },
    {
        "SL": 30,
        "NAME": "Dr. SHAMMY S S",
        "GENDER": "MALE",
        "DEPARTMENT": "SURGERY",
        "DEPT_ID": 1,
        "DESIGNATION": "SURGEON",
        "QUALIFICATIONS": "MBBS, MS, FIAGES, GENERAL AND  LAPAROSCOPEIC SURGEON",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drshammy
    },
    {
        "SL": 31,
        "NAME": "Dr. SHIBINA S",
        "GENDER": "FEMALE",
        "DEPARTMENT": "DERMATOLOGY",
        "DEPT_ID": 6,
        "DESIGNATION": "DERMATOLOGIST",
        "QUALIFICATIONS": "MBBS, MS, DVL",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "4 AM TO 6 PM", "NO-OP", "4 AM TO 6 PM", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drshibinas
    },
    {
        "SL": 32,
        "NAME": "Dr. SNEHA",
        "GENDER": "FEMALE",
        "DEPARTMENT": "CLINICAL PHARMACY",
        "DEPT_ID": 24,
        "DESIGNATION": "CLINICAL PHARMACIST",
        "QUALIFICATIONS": "Pharm.D (doctor of pharmacy)",
        "ACHIEVEMENTS": "",
        "EXPERTISE": "drug Dose Adjustment, patient counselling, herapentic drug monitoring , ADR monitoring , Drug Information.",
        "OP_TIMINGS": ["9 AM TO 5 PM", "9 AM TO 5 PM", "9 AM TO 5 PM", "9 AM TO 5 PM", "9 AM TO 5 PM", "9 AM TO 5 PM", "9 AM TO 5 PM"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drsneham
    },
    {
        "SL": 33,
        "NAME": "Dr. SREEDAS GOPALAKRISHNAN",
        "GENDER": "MALE",
        "DEPARTMENT": "NEPHROLOGY",
        "DEPT_ID": 19,
        "DESIGNATION": "NEPHROLOGIST",
        "QUALIFICATIONS": "",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drsreedas
    },
    {
        "SL": 34,
        "NAME": "Dr. SUGHOSHMITRA",
        "GENDER": "MALE",
        "DEPARTMENT": "PAEDIATRICS",
        "DEPT_ID": 10,
        "DESIGNATION": "PEDIATRICIAN",
        "QUALIFICATIONS": "MBBS , DCH",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummymale
    },
    {
        "SL": 35,
        "NAME": "Dr. SWATHY G R",
        "GENDER": "FEMALE",
        "DEPARTMENT": "GENERAL MEDICINE",
        "DEPT_ID": 2,
        "DESIGNATION": "PHYSICIAN",
        "QUALIFICATIONS": "MBBS, MD, General medicine",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["8 AM to 1 PM & 3 PM to 6 PM", "8 AM to 1 PM & 3 PM to 6 PM", "8 AM to 1 PM & 3 PM to 6 PM", "8 AM to 1 PM & 3 PM to 6 PM", "8 AM to 1 PM & 3 PM to 6 PM", "8 AM to 1 PM & 3 PM to 6 PM", "8 AM to 1 PM & 3 PM to 6 PM"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummyfemale
    },
    {
        "SL": 36,
        "NAME": "Dr. SYAM D GOPAL",
        "GENDER": "MALE",
        "DEPARTMENT": "GENERAL MEDICINE",
        "DEPT_ID": 2,
        "DESIGNATION": "SENIOR CONSULTANT",
        "QUALIFICATIONS": "MBBS, MD, ( General medicine)",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["9 AM to 1 PM & 4 pm to 6 pm", "9 AM to 1 PM", "9 AM to 1 PM & 4 pm to 6 pm", "9 AM to 1 PM", "9 AM to 1 PM & 4 pm to 6 pm", "9 AM to 1 PM", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummymale
    },
    {
        "SL": 37,
        "NAME": "Dr. SYAMLAL S",
        "GENDER": "MALE",
        "DEPARTMENT": "NEUROLOGY",
        "DEPT_ID": 9,
        "DESIGNATION": "NEUROLOGIST",
        "QUALIFICATIONS": "MBBS, MD (Med) DM (NEUROLOGY) , DNB (NEUROLOGY), MNAMS",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drsyamlal
    },
    {
        "SL": 38,
        "NAME": "Dr. TITY PRABHAKARAN",
        "GENDER": "MALE",
        "DEPARTMENT": "NEUROLOGY",
        "DEPT_ID": 9,
        "DESIGNATION": "NEUROLOGIST",
        "QUALIFICATIONS": "MBBS, MD, DM (Neurology)",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["10.30 am to 3 pm", "10.30 am to 3 pm", "10.30 am to 3 pm", "10.30 am to 3 pm", "NO-OP", "10.30 am to 3 pm", "10 am to 1 pm"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drtitty
    },
    {
        "SL": 39,
        "NAME": "Dr. VIJAYAKUMAR G",
        "GENDER": "MALE",
        "DEPARTMENT": "GENERAL MEDICINE",
        "DEPT_ID": 2,
        "DESIGNATION": "SENIOR CONSULTANT",
        "QUALIFICATIONS": "MBBS, MD, PGDC, ( DIABETOLOGY)",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["9 AM TO 1 PM", "9 AM TO 1 PM", "9 AM TO 1 PM", "9 AM TO 1 PM", "9 AM TO 1 PM", "9 AM TO 1 PM", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummymale
    },
    {
        "SL": 40,
        "NAME": "Dr. VINAYAK SUSRUTHAN",
        "GENDER": "MALE",
        "DEPARTMENT": "ENT",
        "DEPT_ID": 17,
        "DESIGNATION": "ENT",
        "QUALIFICATIONS": "MBBS, MS , MS otorhinolaryngology (ENT)",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["8 PM TO 1 PM & 3 PM TO 6 PM", "8 PM TO 1 PM & 3 PM TO 6 PM", "8 PM TO 1 PM & 3 PM TO 6 PM", "8 PM TO 1 PM & 3 PM TO 6 PM", "8 PM TO 1 PM & 3 PM TO 6 PM", "8 PM TO 1 PM & 3 PM TO 6 PM", "8 PM TO 1 PM & 3 PM TO 6 PM"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drvinayaksisruthan
    },
    {
        "SL": 41,
        "NAME": "Dr. VISHNU UNNITHAN",
        "GENDER": "MALE",
        "DEPARTMENT": "ORTHO",
        "DEPT_ID": 4,
        "DESIGNATION": "ORTHOPAEDICS",
        "QUALIFICATIONS": "",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["3 PM TO 6 PM", "NO-OP", "NO-OP", "3 PM TO 6 PM", "3 PM TO 6 PM", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummymale
    },
    {
        "SL": 42,
        "NAME": "Dr. YOGESH KULKARNI",
        "GENDER": "MALE",
        "DEPARTMENT": "CARDIOLOGY",
        "DEPT_ID": 13,
        "DESIGNATION": "CARDIOLOGIST",
        "QUALIFICATIONS": "MBBS, MD, DM, CONSULTANT INTERNAL CARDIOLOGIST",
        "ACHIEVEMENTS": "",
        "EXPERTISE": " ",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "10 AM TO 2 PM", "10 AM TO 2 PM"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummymale
    },
    {
        "SL": 43,
        "NAME": "SRUTHY R PRAMOD",
        "GENDER": "FEMALE",
        "DEPT_ID": 28,
        "DEPARTMENT": "DIETITAIAN",
        "DESIGNATION": "DIETITAIAN",
        "QUALIFICATIONS": "MSc , CLINICAL NUTRITION & DIETETICS",
        "EXPERTISE": "MEDICAL NUTRITION THERAPY, COUNSELLING NUTRITION MANAGEMENT",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummyfemale
    },
    {
        "SL": 44,
        "NAME": " SUMY KRISHNAN R",
        "GENDER": "FEMALE",
        "DEPT_ID": 23,
        "DEPARTMENT": "PHYSIOTHERAPY",
        "DESIGNATION": "PHYSIOTHERAPIST",
        "QUALIFICATIONS": "",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["8 AM TO 3 PM", "8 AM TO 3 PM", "8 AM TO 3 PM", "8 AM TO 3 PM", "8 AM TO 3 PM", "8 AM TO 3 PM", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drdummyfemale
    },
    {
        "SL": 45,
        "NAME": "Dr. SURABI SUSEELAN ",
        "GENDER": "FEMALE",
        "DEPT_ID": 36,
        "DEPARTMENT": "AYURVEDA",
        "DESIGNATION": "AYURVEDA DOCTOR",
        "QUALIFICATIONS": "",
        "EXPERTISE": "",
        "ACHIEVEMENTS": "",
        "OP_TIMINGS": ["8 AM TO 3 PM", "8 AM TO 3 PM", "8 AM TO 3 PM", "8 AM TO 3 PM", "8 AM TO 3 PM", "8 AM TO 3 PM", "NO-OP"],
        "KNOWN_LANGUAGES": "ENGLISH, MALAYALAM",
        "PHOTO_PATH": drsurabhisuseelan
    }

]




