import React, { useState } from 'react'

import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";

import { NavLink } from 'react-router-dom';

import LogoText from "../icon-component/Logotext";
import Logo from "../icon-component/Logo";



export default function NavDrawer({toggleDrawer,toggleHandler}) {

    const container = window !== undefined ? () => window.document.body : undefined;
    const drawerWidth = 240;
    const navItems = ["Home", "About", "Doctors", "Specialities", "ContactUs"];

    //const [mobileOpen, setMobileOpen] = useState(false);

    // const handleDrawerToggle = () => {
    //     //setMobileOpen(!mobileOpen);
    // };

    const drawer = (
        <Box onClick={toggleHandler} sx={{ textAlign: "center" }}>
            <Logo height={60} width={60} />
            <LogoText height={50} width={200} fill={"#1976d2"} />
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding >
                        <ListItemButton sx={{ textAlign: "left" }}>
                            <NavLink
                                style={(isActive) => ({ textDecoration: 'none', color: isActive ? '#1976d2' : 'inherit' })} to={`/${item}`}>{item}</NavLink>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
            <Drawer
                container={container}
                variant="temporary"
                open={toggleDrawer}
                onClose={toggleHandler}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                }}>
                {drawer}
            </Drawer>
    )
}
