import React, { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";

import { Link } from 'react-router-dom';

import Logo from "../icon-component/Logo";
import LogoText from "../icon-component/Logotext";
import NavDrawer from "./Drawer";
import { TopMiniBar } from "./TopMiniBar";
import { NavMenu } from "./NavMenu"



export default function StickyHeader() {

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(prev => !prev);
  };

  return (
    <>
      <AppBar position='sticky' elevation={1} sx={{ backgroundColor: 'white', color: 'grey.900' }} >
        <TopMiniBar />
        <Container maxWidth='xl'>
          <Toolbar variant='dense'>
            <Box sx={{ display: 'flex', flexDirection: 'row' }} width='100%'>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}>
                  <MenuIcon />
                </IconButton>
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                  <Link to="/" style={{ textDecoration: 'none',cursor: 'pointer'}} >
                  <Logo height={60} width={60} />
                  <LogoText height={50} width={200} fill={"#1976d2"} />
                  </Link>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}></Box>
              <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexDirection: 'row', justifyContent: "flex-end" }}>
                <NavMenu />
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <NavDrawer toggleDrawer={mobileOpen} toggleHandler={handleDrawerToggle} />
    </>
  );
}