import * as React from "react";
import { SVGProps } from "react";

const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="logo_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 213 237"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <clipPath id="logo_svg__clip-path">
        <path
          className="logo_svg__cls-1"
          d="M27.54 208.01h-2.2V70.02h2.2v137.99"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-2">
        <path className="logo_svg__cls-1" d="M25.34 70.02h2.2v137.99h-2.2z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-3">
        <path
          className="logo_svg__cls-1"
          d="M25.34 208.01h-3.86V70.02h3.86v137.99"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-4">
        <path className="logo_svg__cls-1" d="M21.48 70.02h3.86v137.99h-3.86z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-5">
        <path
          className="logo_svg__cls-1"
          d="M21.48 208.01h-3.85V70.02h3.85v137.99"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-6">
        <path className="logo_svg__cls-1" d="M17.63 70.02h3.86v137.99h-3.86z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-7">
        <path
          className="logo_svg__cls-1"
          d="M17.63 208.01h-1.24V70.02h1.24v137.99"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-8">
        <path className="logo_svg__cls-1" d="M16.39 70.02h1.23v137.99h-1.23z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-9">
        <path
          className="logo_svg__cls-1"
          d="M8.39 217.42a4.41 4.41 0 0 1-.34-1.7v-1.64a4.5 4.5 0 0 1 4.5-4.49h131.14v2.09H10.43a2 2 0 0 0-2 2v3.7"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-10">
        <path className="logo_svg__cls-1" d="M8.05 209.59h135.64v7.83H8.05z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-11">
        <path
          className="logo_svg__cls-1"
          d="M10.05 219.46a4.54 4.54 0 0 1-1.66-2v-3.7a2 2 0 0 1 2-2h133.3v3.68H12.09a2 2 0 0 0-2 2v2.06"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-12">
        <path className="logo_svg__cls-1" d="M8.39 211.68h135.3v7.77H8.39z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-13">
        <path
          className="logo_svg__cls-1"
          d="M11.93 220.17a4.57 4.57 0 0 1-1.88-.71v-2.06a2 2 0 0 1 2-2h131.6v3.6H13.76a2.05 2.05 0 0 0-1.83 1.13"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-14">
        <path
          className="logo_svg__cls-1"
          d="M10.05 215.36h133.64v4.81H10.05z"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-15">
        <path
          className="logo_svg__cls-1"
          d="M143.69 220.21H12.55a4.21 4.21 0 0 1-.62 0 2.05 2.05 0 0 1 1.83-1.21h129.93v1.17"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-16">
        <path
          className="logo_svg__cls-1"
          d="M11.93 219.04h131.76v1.17H11.93z"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-17">
        <path
          className="logo_svg__cls-1"
          d="M8.39 57.82a4 4 0 0 1-.33-1.59V52.7A3.91 3.91 0 0 1 12 48.79h83V51H12.31a3.91 3.91 0 0 0-3.92 3.91v2.88"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-18">
        <path className="logo_svg__cls-1" d="M8.06 48.79h86.9v9.03H8.06z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-19">
        <path
          className="logo_svg__cls-1"
          d="M10.06 59.65a3.86 3.86 0 0 1-1.67-1.83v-2.88A3.91 3.91 0 0 1 12.31 51H95v4H14a3.91 3.91 0 0 0-3.92 3.92v.77"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-20">
        <path className="logo_svg__cls-1" d="M8.39 51.03h86.56v8.62H8.39z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-21">
        <path
          className="logo_svg__cls-1"
          d="M12.77 60.15H12a3.89 3.89 0 0 1-1.91-.5v-.77A3.91 3.91 0 0 1 14 55h81v3.93H15.64a3.93 3.93 0 0 0-2.87 1.26"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-22">
        <path className="logo_svg__cls-1" d="M10.06 54.96h84.89v5.19H10.06z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-23">
        <path
          className="logo_svg__cls-1"
          d="M95 60.15H12.77a3.93 3.93 0 0 1 2.87-1.26H95v1.26"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-24">
        <path className="logo_svg__cls-1" d="M12.77 58.89h82.18v1.26H12.77z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-25">
        <path
          className="logo_svg__cls-1"
          d="M164.51 227.82h-11.14v-.57h8.94V117.32h2.2v110.5"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-26">
        <path
          className="logo_svg__cls-1"
          d="M153.36 117.32h11.14v110.5h-11.14z"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-27">
        <path
          className="logo_svg__cls-1"
          d="M162.31 227.25h-8.94v-1.32h5.08V117.32h3.86v109.93"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-28">
        <path
          className="logo_svg__cls-1"
          d="M153.36 117.32h8.95v109.94h-8.95z"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-29">
        <path
          className="logo_svg__cls-1"
          d="M158.45 225.93h-5.08v-1.33h1.23V117.32h3.85v108.61"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-30">
        <path
          className="logo_svg__cls-1"
          d="M153.36 117.32h5.09v108.61h-5.09z"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-31">
        <path
          className="logo_svg__cls-1"
          d="M154.6 224.6h-1.23V117.78h.95v-.46h.28V224.6"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-32">
        <path
          className="logo_svg__cls-1"
          d="M153.36 117.32h1.23V224.6h-1.23z"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-33">
        <path
          className="logo_svg__cls-1"
          d="M212.72 58.44h-1.29V53h-45.26v-5.9h46.55v11.34m-115.92 0H95V47.09h48.74V53H96.8v5.45"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-34">
        <path
          className="logo_svg__cls-1"
          d="M94.95 47.09h117.77v11.34H94.95z"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-35">
        <path
          className="logo_svg__cls-1"
          d="M211.43 58.44h-45.26V53h45.26v5.45m-67.74 0H96.8V53h46.89v5.45"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-36">
        <path className="logo_svg__cls-1" d="M96.8 52.99h114.63v5.44H96.8z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-37">
        <path
          className="logo_svg__cls-1"
          d="M144.77 117.78h-1.08v-.46h1.08v.46"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-38">
        <path className="logo_svg__cls-1" d="M143.69 117.32h1.08v.46h-1.08z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-39">
        <path
          className="logo_svg__cls-1"
          d="M145.84 117.78h-1.07v-.46h1.07v.46"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-40">
        <path className="logo_svg__cls-1" d="M144.77 117.32h1.07v.46h-1.07z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-41">
        <path
          className="logo_svg__cls-1"
          d="M146.91 117.78h-1.07v-.46h1.07v.46"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-42">
        <path className="logo_svg__cls-1" d="M145.84 117.32h1.07v.46h-1.07z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-43">
        <path
          className="logo_svg__cls-1"
          d="M153.37 117.78h-6.46v-.46h6.46v.46"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-44">
        <path className="logo_svg__cls-1" d="M146.92 117.32h6.45v.46h-6.45z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-45">
        <path
          className="logo_svg__cls-1"
          d="M154.32 117.78h-.95v-.46h.95v.46"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-46">
        <path
          className="logo_svg__cls-1"
          d="M154.32 117.32h-10.63V.01h10.63v.83h-4.84v114.63h4.84v1.85"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-47">
        <path className="logo_svg__cls-1" d="M143.69.01h10.62v117.31h-10.62z" />
      </clipPath>
      <clipPath id="logo_svg__clip-path-48">
        <path
          className="logo_svg__cls-1"
          d="M154.32 115.47h-4.84V.84h4.84v114.63"
        />
      </clipPath>
      <clipPath id="logo_svg__clip-path-49">
        <path className="logo_svg__cls-1" d="M149.48.84h4.84v114.63h-4.84z" />
      </clipPath>
      <style>
        {
          ".logo_svg__cls-1{fill:none}.logo_svg__cls-2{fill:#95c11f}.logo_svg__cls-3{fill:#95c120}.logo_svg__cls-4{fill:#96c121}.logo_svg__cls-5{fill:#96c122}.logo_svg__cls-38{fill:#312783}"
        }
      </style>
    </defs>
    <path
      className="logo_svg__cls-2"
      d="M27.51 228.58H8.41a1.69 1.69 0 0 1-1.69-1.68V48.78a1.69 1.69 0 0 1 1.69-1.69h19.1a1.69 1.69 0 0 1 1.69 1.69V226.9a1.69 1.69 0 0 1-1.69 1.68"
    />
    <path
      className="logo_svg__cls-3"
      d="M23.66 227.25H9.48a1.68 1.68 0 0 1-1.68-1.68V50.67A1.68 1.68 0 0 1 9.48 49h14.18a1.68 1.68 0 0 1 1.68 1.68v174.9a1.68 1.68 0 0 1-1.68 1.68"
    />
    <path
      className="logo_svg__cls-4"
      d="M19.8 225.93h-9.25a1.69 1.69 0 0 1-1.68-1.69V52.57a1.68 1.68 0 0 1 1.68-1.68h9.25a1.68 1.68 0 0 1 1.68 1.68v171.67a1.69 1.69 0 0 1-1.68 1.69"
    />
    <path
      className="logo_svg__cls-5"
      d="M15.94 224.6h-4.31a1.68 1.68 0 0 1-1.63-1.68V54.47a1.69 1.69 0 0 1 1.68-1.69h4.31a1.69 1.69 0 0 1 1.69 1.69v168.45a1.69 1.69 0 0 1-1.69 1.68"
    />
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path)",
      }}
    >
      <image
        width={8}
        height={182}
        transform="matrix(.77 0 0 -.77 23.16 209.5)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAC2CAYAAADkz8yOAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAVklEQVRYR+3YMRGAQBBD0b+3N4cWXGAGLyjCB4qWBkqgZ36qFC8GElVVvKTfZT9Wsg0yBj0nlnkDoD1OrwgEAoFAIBAIBAKBQCAQCAQCgUDwDxBfp/oJuPMMZVKYnDgAAAAASUVORK5CYII="
        style={{
          clipPath: "url(#logo_svg__clip-path-2)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-3)",
      }}
    >
      <image
        width={12}
        height={182}
        transform="matrix(.77 0 0 -.77 19.27 209.5)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAC2CAYAAADtJGz0AAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAf0lEQVRoQ+3asRHCQAwFUUkHRzfUYVp0CW6FouSIwOOAderZFynZX4GyuzsuePyO7btE5YzKZ4ycMWrGqFd83ushqNPEHwaEAWFAGBAGhAFhQBgQBoQBYUAYEAaEAWFAGBAGhAFhQBgQBoQBYUAYEAaEAWFAGBAGxB2CvPqksgNsqwxldPiWqwAAAABJRU5ErkJggg=="
        style={{
          clipPath: "url(#logo_svg__clip-path-4)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-5)",
      }}
    >
      <image
        width={12}
        height={182}
        transform="matrix(.77 0 0 -.77 15.72 209.5)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAC2CAYAAADtJGz0AAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAfElEQVRoQ+3aOxWAQAwF0WQ/jkAK+nCBA3QtFQWHgtmWM7dKM09BcowxYkK7j/1copYeGTVq9qilRcke23o8gvKa+GBAGBAGhAFhQBgQBoQBYUAYEAaEAWFAGBAGhAFhQBgQBoQBYUAYEAaEAWFAGBAGhAFhQPwhyNknlQto2wxlhXZ9lwAAAABJRU5ErkJggg=="
        style={{
          clipPath: "url(#logo_svg__clip-path-6)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-7)",
      }}
    >
      <image
        width={8}
        height={182}
        transform="matrix(.77 0 0 -.77 14.39 209.5)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAC2CAYAAADkz8yOAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAUUlEQVRYR+3YoRGAQBADwLvn+8HTzFdGNyiaOhQMggHPbFTEpoFkVVW8pJ9l3eZoOUXLHmPZL9AeZ7cAAAAAAAAAAAAAAAAAAAAAwD9Afp3qB7gzDGWFNFCDAAAAAElFTkSuQmCC"
        style={{
          clipPath: "url(#logo_svg__clip-path-8)",
        }}
      />
    </g>
    <path
      className="logo_svg__cls-2"
      d="M166.17 210.05v17.34a2 2 0 0 1-2 2H8.76a2 2 0 0 1-2-2v-17.34a2 2 0 0 1 2-2h155.37a2 2 0 0 1 2 2"
    />
    <path
      className="logo_svg__cls-3"
      d="M165 213.72v12.65a2 2 0 0 1-2 2H10.43a2 2 0 0 1-2-2v-12.65a2 2 0 0 1 2-2H163a2 2 0 0 1 2 2"
    />
    <path
      className="logo_svg__cls-4"
      d="M163.83 217.4v7.95a2 2 0 0 1-2 2H12.09a2 2 0 0 1-2-2v-7.95a2 2 0 0 1 2-2h149.7a2 2 0 0 1 2 2"
    />
    <path
      className="logo_svg__cls-5"
      d="M162.67 221.08v3.24a2 2 0 0 1-2 2H13.76a2 2 0 0 1-2-2v-3.24a2 2 0 0 1 2-2h146.87a2 2 0 0 1 2 2"
    />
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-9)",
      }}
    >
      <image
        width={180}
        height={14}
        transform="matrix(.77 0 0 -.77 7.09 219.6)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAAOCAYAAACGnDYhAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAfklEQVRoQ+3asQ2CMQxE4edgYCYYhuEYBsbKXxDRIbfEel+V4srTyUVizjmRmhhVQNpJAjzf9yon/a3H7fV9J8Apzj/D0k4+hR6XKidtYS10VjlpCwkQ4UKrh7XQFlo9eEOrlQQYca1y0hYSIF1oNbFODhdaPVhotRJ+TlInB+UEC5CCPE9yAAAAAElFTkSuQmCC"
        style={{
          clipPath: "url(#logo_svg__clip-path-10)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-11)",
      }}
    >
      <image
        width={180}
        height={14}
        transform="matrix(.77 0 0 -.77 7.17 221.45)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAAOCAYAAACGnDYhAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAf0lEQVRoQ+3asQ2EQAxE0dnFB/UQ0w2F0c3FlLQEWGTIKbb+iwgmHFmDtG2MMQQU0aMAkIlJ0vFfoxzwWft2Pt8mSb3ZaxjIxAv9i3JAClxolGKSNHGhUcRd6E6hUYNPjjnKASnwU4hSfEMvUQ5IwTc0kwM1eKG50Kih8TgJlVzVCAuF5vTpmAAAAABJRU5ErkJggg=="
        style={{
          clipPath: "url(#logo_svg__clip-path-12)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-13)",
      }}
    >
      <image
        width={176}
        height={10}
        transform="matrix(.77 0 0 -.77 9.16 221.82)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALAAAAAKCAYAAAAU5tRNAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAZElEQVRYR+3ZsQ2AMAxE0XMwrMMCbMNcbMMCTGSKpHdrS//VvzxFlmIREQKaGlkAVOaS9Lxn1gHl3Nc3Bzxsz1qgpDVgzzqgJAaM1lySNk4INDUHPHiB0dM6IY6sA0oyPjLQ2Q8AcgtKgTpvgAAAAABJRU5ErkJggg=="
        style={{
          clipPath: "url(#logo_svg__clip-path-14)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-15)",
      }}
    >
      <image
        width={176}
        height={5}
        transform="matrix(.77 0 0 -.77 10.42 222.01)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALAAAAAFCAYAAADlsGaYAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAARklEQVRIS2P8////f4ZRMAqGKGAipGAUjILBDFgYGBgY5h3VJ6RuFIyCQQOSrC/C2SwMDAwMjBBqFIyCIQcYR9vAo2AoAwAtRQsKCVKsKgAAAABJRU5ErkJggg=="
        style={{
          clipPath: "url(#logo_svg__clip-path-16)",
        }}
      />
    </g>
    <path
      className="logo_svg__cls-2"
      d="M166.45 51v15.1a3.92 3.92 0 0 1-3.92 3.9H10.64a3.93 3.93 0 0 1-3.92-3.9V51a3.93 3.93 0 0 1 3.92-3.92h151.89a3.92 3.92 0 0 1 3.92 3.92"
    />
    <path
      className="logo_svg__cls-3"
      d="M165.28 54.94V65a3.91 3.91 0 0 1-3.92 3.92h-149A3.91 3.91 0 0 1 8.39 65V54.94A3.91 3.91 0 0 1 12.31 51h149.05a3.91 3.91 0 0 1 3.92 3.91"
    />
    <path
      className="logo_svg__cls-4"
      d="M164.11 58.88v5a3.91 3.91 0 0 1-3.91 3.91H14a3.91 3.91 0 0 1-3.92-3.91v-5A3.91 3.91 0 0 1 14 55h146.2a3.91 3.91 0 0 1 3.91 3.92"
    />
    <path
      className="logo_svg__cls-5"
      d="M162.94 62.81a3.92 3.92 0 0 1-3.94 3.92H15.64a3.92 3.92 0 0 1 0-7.84H159a3.92 3.92 0 0 1 3.91 3.92"
    />
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-17)",
      }}
    >
      <image
        width={116}
        height={16}
        transform="matrix(.77 0 0 -.77 6.8 59.7)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHQAAAAQCAYAAADH/1trAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAeklEQVRYR+3ZsRGAMAxD0e9gYCamYTamYaxQkN61dXq1Op3FHYk558RkjCpgvSTA815Vzhq4r/cvdIusstZEAozYq5w1sS7Uharw5IpJgPCFyvgvdBxVzppYk+tCVazJdaEqEiA9uTL8DRWzfiycVc6a8OSKCT+fafkA4qILo54rSzgAAAAASUVORK5CYII="
        style={{
          clipPath: "url(#logo_svg__clip-path-18)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-19)",
      }}
    >
      <image
        width={116}
        height={15}
        transform="matrix(.77 0 0 -.77 6.88 61.53)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHQAAAAPCAYAAAA1f+slAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAeklEQVRYR+3ZsRGAMAxD0e9gYB16tmEwtqFno1CQGrfYp1er0ynJXaz33pEyWhSQXBzgvLYoJz937DcwCjVbPsOShwNMNkU5ScIBmnmUkyRUaDHjyFWhVYyFzlFOkhiF6pVbhRZazHuHtjXKSRJaaDEO4FpoGabflloeXNYLjEfm2kwAAAAASUVORK5CYII="
        style={{
          clipPath: "url(#logo_svg__clip-path-20)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-21)",
      }}
    >
      <image
        width={116}
        height={10}
        transform="matrix(.77 0 0 -.77 8.87 61.91)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHQAAAAKCAYAAABlsnqWAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAY0lEQVRYR+3YsQ2AQAhG4ccdzuMCbuNcbuMCToSNjYkJrZD/6y55HYHiLCICaWNkgdTiAMe5Zp381L5dr7cDmM3PWOpxgGGedVKEA0wNtI1nQ5eskyJ0cpt5Tq42tAvTx0IvNzCoC0UbTEyVAAAAAElFTkSuQmCC"
        style={{
          clipPath: "url(#logo_svg__clip-path-22)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-23)",
      }}
    >
      <image
        width={112}
        height={6}
        transform="matrix(.77 0 0 -.77 11.89 62.16)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHAAAAAGCAYAAAAbmxqXAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAASklEQVRIS2P8////f4ZRMGQBEyEFo2BwAxYGBgaGuUf0CakbBYMEJNtcROGzMDAwMDAxMmNVPAoGP4BGIAshdaNgkALG0UbM0AYADP4LFs24DkwAAAAASUVORK5CYII="
        style={{
          clipPath: "url(#logo_svg__clip-path-24)",
        }}
      />
    </g>
    <path
      className="logo_svg__cls-2"
      d="M166.17 228.58h-22.48V47.09h22.48v181.49"
    />
    <path
      className="logo_svg__cls-3"
      d="M162.31 227.25h-17.54V48.99h17.54v178.26"
    />
    <path
      className="logo_svg__cls-4"
      d="M158.45 225.93h-12.61V50.89h12.61v175.04"
    />
    <path className="logo_svg__cls-5" d="M154.6 224.6h-7.69V52.78h7.69V224.6" />
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-25)",
      }}
    >
      <image
        width={20}
        height={146}
        transform="matrix(.77 0 0 -.77 151.64 229.27)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAACSCAYAAABFYOrIAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAoElEQVRoQ+3auw0CQQwAUX/2bmshoQbKowjaoa694AQR2VxAMBNZsvTcgHOtteLCxmd4ve9RMaKyo3KLzi0qR3SNqJznnPPc1YyuPTr3GD3jcXt+wfp5BiTIE+QJ8gR5gjxBniBPkCfIE+QJ8gR5gjxBniBPkCfIE+QJ8gR5gjxBniBPkCfIE+QJ8gR5gjxBniBPkCfIE+QJ8v4fzKsfvA9wjgwd42mgWwAAAABJRU5ErkJggg=="
        style={{
          clipPath: "url(#logo_svg__clip-path-26)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-27)",
      }}
    >
      <image
        width={20}
        height={145}
        transform="matrix(.77 0 0 -.77 151.07 228.35)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAACRCAYAAADD9JhmAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAmElEQVRoQ+3asQ3DMAwF0U9ajpcJMkdGTJFxspdcBK5cnsp7AAGCxU3AmnPOLDSu5ft7pmqkr8nI1iNdj2w10rX/9z7StWf0kffrcwv27QIZ5AxyBjmDnEHOIGeQM8gZ5AxyBjmDnEHOIGeQM8gZ5AxyBjmDnEHOIGeQM8gZ5AxyBjmDnEHOIGeQM8gZ5AxyBrnlwVr9j30C5HcMG/Rh+VwAAAAASUVORK5CYII="
        style={{
          clipPath: "url(#logo_svg__clip-path-28)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-29)",
      }}
    >
      <image
        width={12}
        height={144}
        transform="matrix(.77 0 0 -.77 151.61 227.23)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAACQCAYAAAA80YrfAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAdUlEQVRYR+3ZuxHCQBAE0Z37xINHKMpOeSgA8jossCiqz5DXz1qnJ4HNWmvVhvE5ztejkl4to3pmtYxqmdUz6nhe36D9nPnDgDAgDAgDwoAwIAwIA8KAMCAMCAPCgDAgDAgDwoAwIAwIA8KAMCAMiPuD7L7X3jllDBkg1pDpAAAAAElFTkSuQmCC"
        style={{
          clipPath: "url(#logo_svg__clip-path-30)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-31)",
      }}
    >
      <image
        width={8}
        height={142}
        transform="matrix(.77 0 0 -.77 151.39 226.11)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAACOCAYAAAAM5klOAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAATUlEQVRYR+3YsREAEBBE0bujH7lmVKYbkaZOxAgMsZm/0QZvG1h1d5dL4iy1JTENYhql5L6AHWdbAAAAAAAAAAAAAAAAAAAAvwB9nUUDHEUMFTezgpEAAAAASUVORK5CYII="
        style={{
          clipPath: "url(#logo_svg__clip-path-32)",
        }}
      />
    </g>
    <path
      className="logo_svg__cls-38"
      d="M212.72 47.09v22.93H94.95V47.09h117.77"
    />
    <path
      className="logo_svg__cls-38"
      d="M211.43 52.99v15.38H96.8V52.99h114.63"
    />
    <path
      className="logo_svg__cls-38"
      d="M210.14 58.9v7.83H98.65V58.9h111.49"
    />
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-33)",
      }}
    >
      <image
        width={160}
        height={18}
        transform="matrix(.77 0 0 -.77 93.28 60.19)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAAASCAYAAADczdVTAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAm0lEQVRoQ+3ayw2AIBBFUflZjg3YmCXYmA3YEK6cISHBhWFmc8+KyCxfHpAYaq11AZzErwFgpvwu9u0czQG/XffRfZMAxhi6TWA2CWAigHDQNCDXQdiTAOZEA8IeDQhXEsCSCSDs6SMkEUDY0zsgDQgHegQXWQJm9AimAeFAAriuNCDsNXfANJoDpmgakADCnjYgjxA4CPyQCk8Pgy8Lw4CiHUkAAAAASUVORK5CYII="
        style={{
          clipPath: "url(#logo_svg__clip-path-34)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-35)",
      }}
    >
      <image
        width={156}
        height={11}
        transform="matrix(.77 0 0 -.77 94.97 60.18)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJwAAAALCAYAAACK2+S2AAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAcklEQVRYR+3ZwQnAIAyF4WoyTxfoYo7QxbpAF0pPVUGwFExO/3cSzfHxFExmZhsQJH8NACvpuzj2czYH/HbdZdirgcs5DYfAajVwQuAQoGs4nnPwVwOnQsPBX7tShYaDv67hCBz8tcCpzOaAJRI/DYj0AA/EC1ISIBhLAAAAAElFTkSuQmCC"
        style={{
          clipPath: "url(#logo_svg__clip-path-36)",
        }}
      />
    </g>
    <path
      className="logo_svg__cls-38"
      d="M143.69-.45h22.48v117.77h-22.48V-.45"
    />
    <path className="logo_svg__cls-38" d="M149.48.84h15.08v114.63h-15.08V.84" />
    <path
      className="logo_svg__cls-38"
      d="M155.26 2.14h7.68v111.49h-7.68V2.14z"
    />
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-37)",
      }}
    >
      <image
        width={8}
        height={4}
        transform="matrix(.77 0 0 -.77 141.67 119.41)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAECAYAAACzzX7wAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAL0lEQVQIW2P8////fwY8gAmfJAMDAwMLjHHkZj3D33+/GX7/+8ngot0LV8BIyAoASXsRAcq4upUAAAAASUVORK5CYII="
        style={{
          clipPath: "url(#logo_svg__clip-path-38)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-39)",
      }}
    >
      <image
        width={8}
        height={4}
        transform="matrix(.77 0 0 -.77 143 119.41)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAECAYAAACzzX7wAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAIklEQVQIW2P8////fwY8gAmfJIqCfddKGfZcycdQwEjICgBaegwHpm6J7QAAAABJRU5ErkJggg=="
        style={{
          clipPath: "url(#logo_svg__clip-path-40)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-41)",
      }}
    >
      <image
        width={8}
        height={4}
        transform="matrix(.77 0 0 -.77 143.56 119.41)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAECAYAAACzzX7wAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAL0lEQVQIW2P8////fwY8gAmfJAMDAwMLjLHnSgHD338/Gf7+/83gpT8HroCRkBUASC8RAYzIajMAAAAASUVORK5CYII="
        style={{
          clipPath: "url(#logo_svg__clip-path-42)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-43)",
      }}
    >
      <image
        width={16}
        height={4}
        transform="matrix(.77 0 0 -.77 144.75 119.41)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAECAYAAACHtL/sAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAARUlEQVQYV2P8////fwYKABMhBYQAC4yx41Iaw99/vxj+/vvN8Pf/T4Z//38z/PsP4/9m+Pf/D8O//78Z4q1OoRjASKkXAMRvJgHjX+rxAAAAAElFTkSuQmCC"
        style={{
          clipPath: "url(#logo_svg__clip-path-44)",
        }}
      />
    </g>
    <image
      width={4}
      height={3}
      transform="matrix(.79 0 0 .93 152.15 116.04)"
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAACXBIWXMAAA3+AAAN/gEmzVBtAAAAJklEQVQIW2NcfsL5/7///xgYGBgYGBn/MzAxoAEMARYGBoaDyAIAqVwHeA/ddn0AAAAASUVORK5CYII="
      style={{
        clipPath: "url(#logo_svg__clip-path-45)",
      }}
    />
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-46)",
      }}
    >
      <image
        width={20}
        height={155}
        transform="matrix(.77 0 0 -.77 141.83 118.97)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAACbCAYAAABib7sAAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAqElEQVRoQ+3buQ0CUQwAUYNolgqgKcqgANpZH58AQYrQbEAwEzmwntyAD2utFTt2/Lbwa6f3cDnfIreObavIrMh8zZUdmR3VE1Ud3RPVK2YmelZUT9wf1w+4+4WCPEGeIE+QJ8gT5AnyBHmCPEGeIE+QJ8gT5AnyBHmCPEGeIE+QJ8gT5AnyBHmCPEGeIE+QJ8gT5AnyBHmCPEGeIE+QJ8j7f/Cw9+fCE8GrNi+pNy7uAAAAAElFTkSuQmCC"
        style={{
          clipPath: "url(#logo_svg__clip-path-47)",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#logo_svg__clip-path-48)",
      }}
    >
      <image
        width={12}
        height={152}
        transform="matrix(.77 0 0 -.77 147.21 117.16)"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAACYCAYAAADQggiyAAAACXBIWXMAAA5NAAAOTQFG1WgJAAAAfElEQVRYR+3ZsQ2EMAxAUYMYjRFuGBZhJAZgn8S+IqK55j5UFP9JllL4ywNkqqqKG+Z/C7+W6/FZ92gtx/QxmRk9K1rPOM4tIh5cMCAMCAPCgDAgDAgDwoAwIAwIA8KAMCAMCAPCgDAgDAgDwoAwIAwIA8KAeGEw3f3m/AL5RyEp4v73OgAAAABJRU5ErkJggg=="
        style={{
          clipPath: "url(#logo_svg__clip-path-49)",
        }}
      />
    </g>
  </svg>
);

export default SvgLogo;
