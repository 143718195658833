import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Button, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid";


import _00 from "../../assets/slides/00.jpg";
import _02 from "../../assets/slides/02.jpg";
import _03 from "../../assets/slides/03.jpg";
import _04 from "../../assets/slides/04.jpg";
import _05 from "../../assets/slides/05.jpg";


const images = [
  {
    label: "",
    imgPath: _00
  },
  {
    label: "",
    imgPath: _02
  },
  {
    label: "",
    imgPath: _03
  },
  {
    label: "",
    imgPath: _04
  },
  {
    label: "",
    imgPath: _05
  },

];

const CarouselUI = () => {
  function Item(props) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Paper
            square
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "background.default",
              width: '100%',
              position: 'relative'
            }}
          >
            <Box
              component="img"
              sx={{
                display: "block",
                overflow: "hidden",
                objectFit: "scale-down",
                height: '100%'
              }}
              src={props.item.imgPath}
              alt={props.item.label}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }



  return (
    <Carousel
      navButtonsProps={{
        // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
        style: {
          backgroundColor: "cornflowerblue",
          borderRadius: 0,
        },
      }}
      navButtonsWrapperProps={{
        // Move the buttons to the bottom. Unsetting top here to override default style.
        style: {
          bottom: "0",
          top: "unset",
        },
      }}

      NextIcon=">" // Change the "inside" of the next button to "next"
      PrevIcon="<"
      indicators={false}
      animation={'slide'}
    >
      {images.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
};

export default CarouselUI;
