import React, { useState,useEffect } from 'react'

import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"



export default function PhotoFrame(props) {

   const {leader,height} = props;
   //console.log("data",leader);
   
   const [person,setPerson ] = useState({ person_name: leader?.person_name, designation: leader?.designation,photo:leader?.photo});

   useEffect(()=>{
   console.log("data",leader);
    if(leader){
      setPerson(leader);
    }
  
   },[leader])
   
   
  return (
    <Box
              aria-label="card"
              component="div"
              sx={{
                minHeight:height,
                borderRadius: 4,
                overflow: "hidden",
                marginX: 0.5,
                border: 1,
                borderColor: "#1976d2"
              }}
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              
            >
              <Box
                component="img"
                src={person?.photo}
                alt={"photo"}
                width={"100%"}
                height={height}
                sx={{ borderBottom: 1, borderColor: "#1976d2" }}
                flexGrow={1}
              />

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                flexGrow={2}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  flexGrow={1}
                >
                  <Typography variant="h5" p={0} fontWeight={"900"}>{`${person?.person_name}`}</Typography>
                  <Typography variant="body1" fontSize={20} px={2} fontWeight={"400"}>{person?.designation}</Typography>
                </Box>

              </Box>
            </Box>
  )
}
