import React from 'react'

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import WhatsappOutlined from "@mui/icons-material/WhatsappOutlined";

export const TopMiniBar = () => {
  return (
    <Box sx={{display:{ xs: "none", sm: "flex" },flexDirection:'row',borderBottom: 1,borderColor: "grey.300"}}  p={.2}>
        <Box sx={{}}>
            <Typography variant="subtitle1" fontWeight={'bold'} color={"#c71d22"} px={.5}>{`Opening Hours: 24 X 7`}</Typography>
        </Box>
        <Box sx={{flexGrow:1}}></Box>
        <Box sx={{display:'flex',flexDirection:'row', justifyContent:'space-between', alignItems:'center',paddingX:4}}>
                <WhatsappOutlined sx={{ color: "#11bb17" }}/>
                <Typography variant="subtitle2" color={'#11bb17'} px={.5} sx={{alignContent:'baseline'}} fontWeight={'bold'}>{`+91 8714607901`}</Typography>
        </Box>
    </Box>
  )
}
