import * as React from "react";
import { SVGProps } from "react";

const LogoText = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 789 204"
    width="1em"
    height="1em"
    {...props}
  >
    <text
      transform="translate(5.85 133.6)"
      style={{
        fontSize: 160,
        fontFamily: "Gotham-Black,Gotham Black",
        fontWeight: 800,
      }}
    >
      {"SSNMMH"}
    </text>
    <text
      transform="translate(10.00 186.73)"
      style={{
        fontSize: "42.03px",
        fontFamily: "Gotham-Bold,Gotham",
        fontWeight: 700,
        textAlign:"left"
      }}
    >
      {"A HOLISTIC HEALTH CENTERE"}
    </text>
  </svg>
);

export default LogoText;
