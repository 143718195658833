
import React from 'react';
import './App.css';
import { Route, Switch, Redirect } from "react-router-dom"




import CarouselUI from './home/carousel/Carousel';
import StickyHeader from './home/header/StickyHeader';
import MainSection from './home/main/Container';
import DoctorStepper from './home/carousel/DoctorStepper';
import Footer from './home/footer/Footer'
import About from './home/pages/About';
import Specialities from './home/pages/Specialities';
import Speciality from './home/pages/Speciality';
import Doctors from './home/pages/Doctors';
import DoctorProfile from './home/pages/DoctorProfile';
import ContactUs from './home/pages/ContactUs';
import Privacy from './home/pages/Privacy';



const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
});


function App() {
  return (
    <div className="App">

      <StickyHeader />

      <Switch>
        
        <Route path="/" exact>
           <Redirect  to="/Home"></Redirect>
        </Route>
       
        <Route path="/Home" exact>
          <CarouselUI />
          <MainSection />
          {/* <DoctorStepper /> */}
        </Route>

        <Route path="/About">
          <About />
        </Route>

        <Route path="/Specialities" exact>
          <Specialities />
        </Route>

        <Route path="/Specialities/:id">
          <Speciality/>
        </Route>

        <Route path="/Doctors" exact>
          <Doctors />
        </Route>

        <Route path="/Doctors/:name">
            <DoctorProfile/>
        </Route>

        <Route path="/ContactUs">
            <ContactUs/>
        </Route>

        <Route path="/Privacy">
            <Privacy/>
        </Route>

      </Switch>
      <Footer />
    </div>
  );
}

export default App;
