import React,{useEffect} from "react";
import { Box, Button, Container, Typography, Grid,Stack } from "@mui/material";
import MedIcon from "../icon-component/MedIcon";
import {NavLink} from 'react-router-dom'

import { ShortAbout } from "./ShortAbout";
import { ShortSpeciality } from "./ShortSpeciality";

import specialityBanner from "../../assets/banners/specialities-bg.jpg"
import DoctorStepper from "../carousel/DoctorStepper";


const MainSection = () => {
  useEffect(() => {
    document.title = 'Home';
  },[]);
  return (
      <Stack justifyContent="space-between">
          <Box sx={{paddingY:'70px'}}>
          <ShortAbout/>
         </Box>
         <Box sx={{
            background:`linear-gradient(rgba(255,255,255,.8), rgba(255,255,255,.8)),
            url(${specialityBanner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            paddingY: '70px'}}>
            <ShortSpeciality/>
         </Box>
         <Box sx={{paddingY:'70px'}}>
          <DoctorStepper />
         </Box>
      </Stack>
  );
};

export default MainSection;
