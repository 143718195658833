import React, { useEffect } from 'react'
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import EastIcon from '@mui/icons-material/East';
import Button from "@mui/material/Button"

import { GoogleMap, LoadScript } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '700px'
};

const center = {
    lat: 8.729151,
    lng: 76.733518
};


function ContactUs() {

    useEffect(() => {
        document.title = 'Contact Us';
    }, []);

    return (

        <Stack>

            <Box display={"flex"} justifyContent={"flex-start"} flexDirection={"row"}
                sx={{
                    backgroundRepeat: "no-repeat",
                    minHeight: 400,
                    backgroundSize: "cover",
                    backgroundImage: `url(https://img.freepik.com/free-photo/easy-wireless-yechnology-payment_23-2149105216.jpg?w=1380&t=st=1661534298~exp=1661534898~hmac=bb4580b412c69ae1129e3f85b71e4b39d5e6c5f7f877242771ca76a4e70756fb)`
                }}>
                <Container maxWidth="xl">
                    {/* <Typography variant='h3' px={2} textAlign="left">
              {`Our Specialities`}
            </Typography> */}
                </Container>
            </Box>

            <Box sx={{ width: "100%" }} py={2}>

                <Container maxWidth="xl" >

                    <Grid container spacing={8}>

                        <Grid item xs={12} display="flex" flexDirection={"column"} alignItems="flex-start">
                            <Typography variant='h4' fontWeight={'bold'}>Contact Us</Typography>
                        </Grid>

                        <Grid item xs={12} display="flex" flexDirection={"column"} alignItems="flex-start">
                            <Typography variant='body1' fontWeight={'bold'} py={1}>SIVAGIRI SREE NARAYANA MEDICAL MISSION HOSPITAL</Typography>
                            <Typography variant='body1' color="gray">PUTHENCHANTHA, VARKALA P.O, THIRUVANANATHAPURAM - 695411, KERALA, INDIA </Typography>
                            <Button variant='text' endIcon={<EastIcon />} >View in GoogleMap</Button>
                        </Grid>


                        <Grid item xs={12} md={4} >
                            <Box display="flex" flexDirection={"column"} alignItems="flex-start">
                                <Typography variant='body1' fontWeight={'bold'}>RECEPTION</Typography>
                                <Typography variant='body1' fontWeight={400} color="gray">+91 9400050200</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4} >
                            <Box display="flex" flexDirection={"column"} alignItems="flex-start">
                                <Typography variant='body1' fontWeight={'bold'}>EMERGENCY</Typography>
                                <Typography variant='body1' fontWeight={400} color="gray">+91 8714607917</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4} >
                            <Box display="flex" flexDirection={"column"} alignItems="flex-start">
                                <Typography variant='body1' fontWeight={'bold'}>ENQUIRY</Typography>
                                <Typography variant='body1' fontWeight={400} color="gray">+91 8714607901</Typography>
                            </Box>
                        </Grid>


                        <Grid item xs={12} display="flex" flexDirection={"column"} alignItems="flex-start">
                            <Box display="flex" flexDirection={"column"} alignItems="flex-start">
                                <Typography variant="body1" fontWeight={'bold'}>Email</Typography>
                                <Typography variant="body1" color="gray">enquiries@gmail.com</Typography>
                            </Box>
                        </Grid>


                    </Grid>

                </Container>


                <Grid >

                    <Grid item xs={12} >


<Box>
                        <LoadScript
                            googleMapsApiKey="AIzaSyBuAAsfKjpws6JwEFhVbSR4Vat427l7OMg"
                        >
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={18}
                            >
                                { /* Child components, such as markers, info windows, etc. */}
                                <></>
                            </GoogleMap>
                        </LoadScript>
                        </Box>


                        {/* <Box component="img" sx={{width:'100%',height:700}}  src="https://maps.googleapis.com/maps/api/staticmap?center=8.729151,76.73351&zoom=15&size=700x500&key=AIzaSyBuAAsfKjpws6JwEFhVbSR4Vat427l7OMg">

                        </Box> */}
                        {/* <StaticGoogleMap 
                    rootURL='"https://www.sivagirimissionhospital.com/'
                    size="600x600" className="img-fluid" apiKey="AIzaSyBuAAsfKjpws6JwEFhVbSR4Vat427l7OMg">
  <Marker location="8.729151,76.733518" color="blue" label="P" />
</StaticGoogleMap> */}
                    </Grid>

                </Grid>

            </Box>

        </Stack>
    )
}

export default ContactUs;