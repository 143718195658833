import React from 'react'
import Box from "@mui/material/Box"
import  Container from '@mui/material/Container'
import  Grid from '@mui/material/Grid'
import  Typography from '@mui/material/Typography'

import LogoText from "../icon-component/Logotext"
import Logo from "../icon-component/Logo"
import {Link} from 'react-router-dom'
import { Stack } from '@mui/material'



import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';


export default function Footer() {
  return (
    <Stack sx={{ backgroundColor: "#1976d2", minHeight: '300px', justifyContent: 'space-between' }}>
      <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',flexGrow:1}}>
        <Container maxWidth="xl">
          <Grid container display="flex" flexDirection="row" justifyContent="space-between">
            <Grid item xs={12} md={3}>
              <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between">
                <Box display={"flex"} flexDirection={"row"} justifyContent="flex-start"  py={1}>
                  <Box sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Logo height={60} width={60} />
                  </Box>
                  <Box sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <LogoText height={60} width={200} fill={"white"} />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Typography variant="body1" color="white" >SSNMMH VARKALA</Typography>
                  <Typography variant="body2" color="white" py={1}>PUTHENCHANTHA, VARKALA P.O</Typography>
                  <Typography variant="body2" color="white" >THIRUVANANATHAPURAM - 695411, KERALA</Typography>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent={'space-between'} width='40%' py={1}>
                    <FacebookIcon sx={{ color: "white" }}/>
                    <YouTubeIcon sx={{ color: "white" }}/>
                    <TwitterIcon sx={{ color: "white" }}/>
                    <InstagramIcon sx={{ color: "white" }}/>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start", height: '100%' }}>
                <Typography variant="body1" color="white" >
                  <Link style={{textDecoration:'none',color:'white',cursor:'pointer'}} to={`/Home`}>Home</Link>
                </Typography>
                <Typography variant="body1" color="white" >
                  <Link style={{textDecoration:'none',color:'white',cursor:'pointer'}} to={`/About`}>About Us</Link>
                </Typography>
                <Typography variant="body1" color="white" >
                  <Link style={{textDecoration:'none',color:'white',cursor:'pointer'}} to={`/Doctors`}>Doctors</Link>
                </Typography>
                <Typography variant="body1" color="white" >
                  <Link style={{textDecoration:'none',color:'white',cursor:'pointer'}} to={`/Specialities`}>Specialities</Link>
                </Typography>
                <Typography variant="body1" color="white" >
                  <Link style={{textDecoration:'none',color:'white',cursor:'pointer'}} to={`/ContactUs`}>Contact Us</Link>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent:"space-between", alignItems: "flex-start" }}>

                <Typography variant="body1" color="white">REACH US</Typography>

                <Box sx={{display:'flex',flexDirection:'column', alignItems:'flex-start',marginTop:2}}>
                  <Typography variant="body2" color="white">Ph: +91 9400050200</Typography>
                  <Typography variant="body2" color="white">Email : enquiries@gmail.com</Typography>
                </Box>

                <Box sx={{display:'flex',flexDirection:'column', alignItems:'flex-start', marginTop:2}}>
                  <Typography variant="body1" color="white">PRO</Typography>
                  <Typography variant="body2" color="white">Ph: +91 8714607920</Typography>
                </Box>

                <Box sx={{display:'flex',flexDirection:'column', alignItems:'flex-start',marginTop:2}}>
                  <Typography variant="body1" color="white">ENQUIRIES</Typography>
                  <Typography variant="body2" color="white">Ph: +91 8714607901</Typography>
                </Box>

              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ borderTop: 1, borderColor: "white", paddingY: 1 }}>
        <Container maxWidth="xl">
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',width:'100%' }} >
            <Grid container>
              <Grid item xs={12} md={4}>
                <Typography variant="body2" color={"white"} textAlign="left" fontSize={12}>
                  Copyright © SSNMMH-Varkala - All Rights Reserved
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body2" color={"white"} textAlign="left" fontSize={12}>

                <Link style={{ textDecoration: 'none', color:'white' }} to={`/Privacy`}>{`Privacy Policy`}</Link>
                  
                </Typography>
              </Grid>
              {/* <Grid item xs={12} md={4}>
                <Typography variant="body2" fontSize={12} color={"white"} textAlign="left">
                  Terms of use
                </Typography></Grid> */}
            </Grid>
          </Box>
        </Container>
      </Box>
    </Stack>
  )
}