import React, { useState, useEffect } from 'react'
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from "@mui/material/Button"
import WhatsappOutlined from "@mui/icons-material/WhatsappOutlined";
import { Paper } from '@mui/material'
import SimpleTabs from '../components/SimpleTabs'
import SimpleTable from '../components/SimpleTable'
import EastIcon from '@mui/icons-material/East';
import { useParams, useLocation, useRouteMatch,useHistory } from 'react-router-dom'




const DoctorProfile = () => {
    const { location } = useHistory();
    const [doctorProfile, setDoctorProfile] = useState(location.state);

    useEffect(() => {
        setDoctorProfile(location.state)
        document.title = 'Profile';
    }, [location])

    return (
        <Stack>

            <Box bgcolor={"#f6f6f6"} display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"} sx={{ minHeight: 500 }} py={2}>
                <Container maxWidth="xl" >
                    <Box sx={{ height: '100%' }} display="flex" flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} md={3} sm={12} display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                                <Paper elevation={2} sx={{ height: 300, width: 250, overflow: 'hidden', border: 1, borderColor: "#1976d2" }}>

                                    <Box
                                        component="img"
                                        src={doctorProfile?.PHOTO_PATH}
                                        alt={doctorProfile?.NAME}
                                        sx={{
                                            height: 300,
                                            width: 250,
                                        }}
                                    //sx={{ borderBottom: 1, borderColor: "#1976d2" }}
                                    />

                                </Paper>
                            </Grid>

                            <Grid item xs={12} md={9} sm={12} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                                <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start" >
                                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" >
                                        <Typography variant='h2'>{doctorProfile?.NAME}</Typography>
                                        <Typography variant='h6'>{`Designation`}</Typography>
                                        <Typography variant='h6'>{doctorProfile?.DEPARTMENT}</Typography>
                                    </Box>
                                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" py={2}>
                                        <Typography variant='h6'>Recommend to your loved ones</Typography>
                                        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                            <WhatsappOutlined sx={{ color: "green" }} />
                                            <Typography variant='body1' color={"green"}>Whatsapp</Typography>
                                        </Box>
                                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" py={2}>
                                            <Button disabled variant="contained" color="primary" sx={{ borderRadius: 8, textAlign: "center" }}> Book An Appointment</Button>
                                        </Box>

                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Container>
            </Box>



            <Box >

                <Container maxWidth="xl">

                    <Grid container spacing={2}>

                        <Grid item xs={12} md={3}>
                            <Card sx={{ minWidth: 275, marginY: 2 }} variant="outlined" >
                                <CardContent>
                                    <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                                        Doctor Profile
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ minWidth: 275, marginY: 2 }} variant="outlined">
                                <CardContent>
                                    <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                                        Timings
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ minWidth: 275, marginY: 2 }} variant="outlined" >
                                <CardContent>
                                    <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                                        Videos
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card sx={{ minWidth: 275, marginY: 2 }} variant="outlined" >
                                <CardContent>
                                    <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                                        Articles &#38; Blogs
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={9}>

                            <Box display={"flex"} flexDirection={"column"} justifyContent="flex-start" alignItems={"flex-start"} sx={{ borderBottomWidth: 1, borderColor: "black" }}>
                                <Typography variant='h4' p={2}>Doctor's Profile</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <SimpleTabs qualifications={doctorProfile?.QUALIFICATIONS} expertise={doctorProfile?.EXPERTISE} achievements={doctorProfile?.ACHIEVEMENTS} languages={doctorProfile?.KNOWN_LANGUAGES} />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box display={"flex"} flexDirection={"column"} justifyContent="flex-start" alignItems={"flex-start"} py={4} sx={{ borderBottom: 1, borderBottomColor: `rgba(0, 0, 0, 0.12)` }}>
                                <Typography variant='h4'> Timings</Typography>
                                <SimpleTable timings={doctorProfile?.OP_TIMINGS} />
                            </Box>

                            <Box display={"flex"} flexDirection="column" py={4} alignItems="flex-start" sx={{ borderBottom: 1, borderBottomColor: `rgba(0, 0, 0, 0.12)` }}>
                                <Typography variant='h4'> Videos</Typography>
                                <Button variant='text' endIcon={<EastIcon />} >View All Videos</Button>
                            </Box>

                            <Box display={"flex"} flexDirection="column" py={4} alignItems="flex-start" sx={{ borderBottom: 1, borderBottomColor: `rgba(0, 0, 0, 0.12)` }}>
                                <Typography variant='h4'> Articles &#38; Blogs</Typography>
                                <Button variant='text' endIcon={<EastIcon />}>View All Articles</Button>
                            </Box>

                        </Grid>

                    </Grid>

                </Container>

            </Box>


        </Stack>
    )
}

export default DoctorProfile