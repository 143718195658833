import React,{useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"}>
          <Typography variant='body1' textAlign={"left"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const  SimpleTabs = ({qualifications,expertise,achievements,languages}) => {
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
  
    return (
      <Box sx={{ width: '100%', borderBottom:1, borderBottomColor: `rgba(0, 0, 0, 0.12)` }} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={`Qualifications`} {...a11yProps(0)} />
            <Tab label={`Area of Expertise`} {...a11yProps(1)} />
            <Tab label={`Awards & Achievements`} {...a11yProps(2)} />
            <Tab label={`Languages Known`} {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          { qualifications != "" ? qualifications : "Will update soon" }
        </TabPanel>
        <TabPanel value={value} index={1}>
         {expertise != "" ? expertise : "Will update soon"}
        </TabPanel>
        <TabPanel value={value} index={2}>
        { achievements != "" ? achievements : "Will update soon" }
        </TabPanel>
        <TabPanel value={value} index={3}>
        {languages != "" ? languages : "Will update soon" }
        </TabPanel>
      </Box>
    );
}

export default SimpleTabs


