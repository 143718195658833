import commonbg from '../assets/banners/common-bg.jpg'


export const departments = [
    {
      "id": 1,
      "name": "General Surgery",
      "icon_name": "surgery-svgrepo-com-1",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 2,
      "name": "General Medicine",
      "icon_name": "medicine-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 3,
      "name": "Naturopathy",
      "icon_name": "ayurvedic-bowl-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 4,
      "name": "Orthopedics & Trauma",
      "icon_name": "knee-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 5,
      "name": "Obstetrics & Gynecology",
      "icon_name": "i-womens-health-svgrepo-com",
      "description": "We provide state-of-the-art labour wards and birthing suites. We provide 24/7 epidural and anaesthetic services at par with western standards. Our expertise lies in minimally invasive techniques. A team of highly qualified and experienced gynaecologists. You can consult our expert doctors at Specialist Hospital during the course of your pregnancy, which will help you keep a check on your baby’s health. A timely check-up during your pregnancy will benefit both the mother and child. You can also seek consultation for regular check-ups for any health concerns concerning women. Our gynaecology department provides the best services for women of all ages.",
      "banner":commonbg
    },
    {
      "id": 6,
      "name": "Dermatology & Cosmetology",
      "icon_name": "i-dermatology-svgrepo-com",
      "description": "Our Dermatology clinic for all skin conditions like Acne, Psoriasis, Vitiligo, Eczemas, Allergies and Sexually Transmitted Diseases. Along with Cosmetology and cosmetic procedures",
      "banner":commonbg
    },
    {
      "id": 7,
      "name": "Emergency Medicine",
      "icon_name": "medical-svgrepo-com",
      "description": "Emergency and Trauma Centre involves highly trained medical experts with an emergency response crew, ready to handle emergencies effectively and efficiently. We ensure smooth and expedient management of every patient who requires immediate medical attention. We offer 24/7 trauma and critical care support, dedicated ambulance services and a team of experts to handle any emergency",
      "banner":commonbg
    },
    {
      "id": 8,
      "name": "Psychiatry & Behavioral Medicine",
      "icon_name": "psychologist-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 9,
      "name": "Neurology",
      "icon_name": "brain-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 10,
      "name": "Pediatrics",
      "icon_name": "baby-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 11,
      "name": "Dental Clinical",
      "icon_name": "i-dental-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 12,
      "name": "Gastroenterology",
      "icon_name": "healthcare-stomach-svgrepo-com",
      "description": "We provide High-end procedures include Endoscopy and Colonoscopy. Our institute also provides technologically advanced Endoscopy suite. The team of experts at Specialist deliver the best protocol-driven solutions for all its patients. The high-end procedures including ERCP, Endoscopy and Colonoscopy support the team of doctors to provide the best possible treatment for you",
      "banner":commonbg
    },
    {
      "id": 13,
      "name": "Cardiology",
      "icon_name": "heart-care-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 14,
      "name": "Ophthalmology",
      "icon_name": "eye-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 15,
      "name": "Critical Care",
      "icon_name": "intensive-care-svgrepo-com",
      "description": "Our Critical Care Unit is an advanced unit comprising of highly skilled and efficient team of specialists, doctors and well-trained nursing staff equipped to handle all sorts of Critical Patient Conditions.",
      "banner":commonbg
    },
    {
      "id": 16,
      "name": "Urology",
      "icon_name": "bladder-kidneys-nephron-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 17,
      "name": "ENT",
      "icon_name": "i-ear-nose-throat-svgrepo-com",
      "description": " Our ENT department deals with special facilities and services like Endoscopic Sinus Surgeries and CSF Leak Repair. Other ENT procedures include Coblation Assisted Surgeries, Snoring Surgery, Rhinoplasty, Balloon Sinuplasty, Tympanoplasty and mastoidectomy. Our institute also provides OPD procedures, Diagnostic Endoscopy, Biopsies of Head and Neck Lesions under LA, Ear Lobe Repair and Ear Boring",
      "banner":commonbg
    },
    {
      "id": 18,
      "name": "Blood Bank",
      "icon_name": "blood-donation-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 19,
      "name": "Nephrology",
      "icon_name": "anatomy-kidneys-nephron-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 20,
      "name": "Respiratory Medicine",
      "icon_name": "lungs-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 21,
      "name": "Endocrinology",
      "icon_name": "diabetes-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 22,
      "name": "Laboratory",
      "icon_name": "i-pathology-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 23,
      "name": "Physiotherapy",
      "icon_name": "chiropractic-physiotherapy-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 24,
      "name": "Pharmacy",
      "icon_name": "medical-drugs-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 25,
      "name": "Imaging & Radiology",
      "icon_name": "radiologist-physician-x-ray-radiology-svgrepo-com",
      "description": " ",
      "banner":commonbg
    },
    {
      "id": 26,
      "name": "Homeopathy",
      "icon_name": "noun-homeopathy-3059496",
      "description": " ",
      "banner":commonbg
    },
    {
        "id": 27,
        "name": "Anesthesiology",
        "icon_name": "drugs-svgrepo-com",
        "description": "Administering anaesthesia is an integral part of any surgical procedure. Local or General anaesthesia are prescribed based on the requirement. Our anesthesiologists are highly trained in administering, evaluating and monitoring patients who are under anaesthesia.",
        "banner":commonbg
      },
      {
        "id": 28,
        "name": "Nutrition & Dietetics",
        "icon_name": "drugs-svgrepo-com",
        "description": `Our department of Diet and Nutrition provides a personalized diet plan for you, taking into account your health issues, lifestyle and food preferences. Our experts assess the patients’ nutrition needs and provide a well planned and structured dietary plan.With the help of our Dietitian Nutritionist, you can manage your health conditions much better.

        A healthy diet helps you stay fit and fine, and keep your health issues in control. Including a variety of foods from different fruit groups such as fruits, vegetables, whole grains, low-fat dairy products, lean protein, nuts and seeds, and healthy fats will help keep you away from any health risks. Nutrition and diet are evolving and that’s why it is necessary to reach out to a Dietitian Nutritionist that will give you a diet plan that is based on the latest research and supports your health concerns. Our dietitian nutritionists are highly experienced and treat conditions including diabetes and weight issues. If you or anyone you know wants to lose weight, consult Specialist Hospital today for the best diet for you that will help you gain your desired weight.`,
        "banner":commonbg
      },
      {
        "id": 29,
        "name": "Ambulance Service",
        "icon_name": "medical-truck-svgrepo-com",
        "description": " ",
        "banner":commonbg,
        "type":"services",
      },
        {
          "id": 30,
          "name": "Transfusion Medicine / Blood centre",
          "icon_name": "blood-donation-svgrepo-com",
          "description": " ",
          "banner":commonbg,
          "type":"services",

        },
        {
          "id": 31,
          "name": "ECHO & TMT",
          "icon_name": "cardiogram-svgrepo-com",
          "description": " ",
          "banner":commonbg,
          "type":"services",
        },
        {
          "id": 32,
          "name": "Imaging & Interventional Radiology",
          "icon_name": "radiologist-physician-x-ray-radiology-svgrepo-com",
          "description": " ",
          "banner":commonbg,
          "type":"services",
        },
        {
          "id": 33,
          "name": "Laboratory",
          "icon_name": "i-pathology-svgrepo-com",
          "description": " ",
          "banner":commonbg,
          "type":"services",
        },
        {
          "id": 34,
          "name": "Pharmacy",
          "icon_name": "medical-drugs-svgrepo-com",
          "description": " ",
          "banner":commonbg,
          "type":"services",
        },
        {
          "id": 35,
          "name": "Canteen & Coffee Shop",
          "icon_name": "meal-svgrepo-com",
          "description": " ",
          "banner":commonbg,
          "type":"services",
        },
        {
          "id": 36,
          "name": "Ayurveda",
          "icon_name": "Ayurvedaa",
          "description": " ",
          "banner":commonbg
        },
  ].sort((a,b)=>{

    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;

  });
  

 /*
 import commonbg from '../assets/banners/common-bg.jpg'


export const departments = [
    {
      "id": 1,
      "name": "General Surgery",
      "icon_name": "surgery-svgrepo-com-1",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 2,
      "name": "General Medicine",
      "icon_name": "medicine-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 3,
      "name": "Naturopathy",
      "icon_name": "ayurvedic-bowl-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 4,
      "name": "Orthopedics & Trauma",
      "icon_name": "knee-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 5,
      "name": "Obstetrics & Gynecology",
      "icon_name": "baby-fetus-obstetrics-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 6,
      "name": "Dermatology & Cosmetology",
      "icon_name": "i-dermatology-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 7,
      "name": "Emergency Medicine Department",
      "icon_name": "medical-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 8,
      "name": "Psychiatry & Behavioral Medicine",
      "icon_name": "psychologist-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 9,
      "name": "Neurology",
      "icon_name": "brain-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 10,
      "name": "Pediatrics",
      "icon_name": "baby-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 11,
      "name": "Dental, Clinical, Oral & Maxillofacial Surgery",
      "icon_name": "i-dental-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 12,
      "name": "Gastroenterology",
      "icon_name": "healthcare-stomach-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 13,
      "name": "Cardiology",
      "icon_name": "heart-care-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 14,
      "name": "Ophthalmology",
      "icon_name": "eye-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 15,
      "name": "Critical Care",
      "icon_name": "intensive-care-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 16,
      "name": "Urology",
      "icon_name": "bladder-kidneys-nephron-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 17,
      "name": "ENT",
      "icon_name": "i-ear-nose-throat-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 18,
      "name": "Transfusion Medicine / Blood centre",
      "icon_name": "blood-donation-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 19,
      "name": "Nephrology",
      "icon_name": "anatomy-kidneys-nephron-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 20,
      "name": "Respiratory Medicine",
      "icon_name": "lungs-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 21,
      "name": "Endocrinology",
      "icon_name": "diabetes-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 22,
      "name": "Laboratory",
      "icon_name": "i-pathology-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 23,
      "name": "Physical Medicine & Rehabilitation",
      "icon_name": "chiropractic-physiotherapy-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 24,
      "name": "Pharmacy",
      "icon_name": "medical-drugs-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 25,
      "name": "Imaging & Interventional Radiology",
      "icon_name": "radiologist-physician-x-ray-radiology-svgrepo-com",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
      "id": 26,
      "name": "Homeopathy",
      "icon_name": "noun-homeopathy-3059496",
      "description": " ",
      "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1380&t=st=1661090802~exp=1661091402~hmac=a8105ef8d32379cafa5fb176917a560646a2116224027b240f2787c91bd08689"
    },
    {
        "id": 27,
        "name": "Anesthesiology",
        "icon_name": "drugs-svgrepo-com",
        "description": " ",
        "banner":"https://img.freepik.com/free-photo/young-handsome-physician-medical-robe-with-stethoscope_1303-17818.jpg?w=1060&t=st=1660984118~exp=1660984718~hmac=771f2391c1ad6db4f01285c8bb84f8d053d7862a5c450e3215e11c6db6c9ae97"
      },
      {
        "id": 28,
        "name": " Nutrition & Dietetics",
        "icon_name": "drugs-svgrepo-com",
        "description": " ",
        "banner":commonbg
      },
  
  ];
  

 
 
 
 */